@use "../utilities/borders";
@use "../utilities/grid";
@use "../utilities/sizing";
@use "../utilities/spacing";


.file-viewer {
  @extend .b-0, .mt-1, .grid, .w-100;
  height: 75vh;
  max-width: 100%;
}
.file-viewer-iss {
  border: 0;
}
