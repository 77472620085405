@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/variables";

.featured-items-container {
  background-color: var(--accent-2-100);
  padding: 1.5rem 1.25rem;

  @include mq.mq($from: md){
    padding: 1.5rem 1.5rem;
  }

  @include mq.mq($from: lg){
    padding: 1.5rem 3rem;
  }
}

.welcome-letter {
  @include mq.mq($from: lg) {
    max-width: calc(100% - #{variables.$card-issuer-overview-sm-w + 2rem});
  }

  .welcome-letter-photo {
    width: 6.5rem;
    @include mq.mq($from: lg) {
      width: 11rem;
    }
  }
}
