/*
```image-gallery.html
<div class="lightbox-overlay">
  <div class="lightbox-controls">
    <div class="nav-btns">
      <button class="btn-link no-decoration prev"></button>
      <span class="pages" id="image-counter">1 of 7</span>
      <button class="btn-link no-decoration next"></button>
    </div>
    <button class="btn-close">&times;</button>
  </div>
  <div class="slide-container">
    <div class="viewport">
      <div class="slides">
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
          <div class="caption">
            <p>Text caption goes here if applicable. Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
          <div class="caption">
            <p>Text caption goes here if applicable. Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
          <div class="caption">
            <p>Text caption goes here if applicable. Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
          <div class="caption">
            <p>Text caption goes here if applicable. Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
          <div class="caption">
            <p>Text caption goes here if applicable. Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="slide">
          <img src="https://placekitten.com/1600/900"/>
        </div>
      </div>
    </div>
  </div>
  <div class="thumbnail-container">
    <div class="thumbnails">
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
      <div class="thumbnail">
        <img src="https://placekitten.com/g/1600/900">
      </div>
    </div>
  </div>
</div>
```
*/

@use "../mq";
@use "../functions/text";
@use "../functions/overflow";
@use "../functions/overlay";
@use "../utilities/shadows";
@use "../variables";

$viewport-margin: 0 0.5rem;
$transition-duration: 0.5s;
$image-transition: transform $transition-duration ease;

.lightbox-overlay {
  @include overlay.overlay(0, 1, lightbox, rgba(0, 0, 0, 0.9));

  &.showing,
  &.show,
  &.hiding {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .lightbox-controls {
    align-items: center;
    display: flex;
    flex: 0 0 3rem;
    height: 3rem;
    justify-content: space-between;
    padding: $viewport-margin;

    @include mq.mq($from: xl) {
      flex: 0 0 5rem;
      height: 5rem;
    }
  }

  .btn-link {
    font-size: 1.25rem;
    padding: 0.5rem;

    &[disabled] {
      svg {
        fill: variables.color("gray", 600);
      }
    }

    @include mq.mq($from: xl) {
      font-size: 1.5rem;
    }
  }

  .btn-close {

    color: variables.color("gray", 200);
    padding: 0.5rem;
    @include text.text-sizing(1.25rem, 1.875rem);

    &:disabled {
      color: variables.color("gray", 600);
    }

    &.no-svg {
      @include text.text-sizing(3rem, 0);
    }

    @include mq.mq($from: xl) {
      @include text.text-sizing(1.5rem, 2.25rem);
    }
  }

  .nav-btns {
    span {
      font-size: 1.25rem;

      @include mq.mq($from: xl) {
        font-size: 1.5rem;
      }
    }
  }

  .slide-container {
    align-items: center;
    display: flex;
    flex: 1;
  }

  .viewport {
    margin: 0 0.5rem;
    overflow-x: hidden;

    @include mq.mq($from: md) {
      margin: 0 1.5rem;
    }

    @include mq.mq($from: xl) {
      height: 100%;
      margin: 0 4.5rem;
      overflow-x: visible;
      width: 100%;
    }
  }

  .slides {
    align-items: center;
    display: flex;
    height: 100%;
    transition: $image-transition;
    width: 100%;
  }

  .slide {
    @extend %box-shadow-3;

    margin: $viewport-margin;
    max-height: 100%;
    opacity: 0.25;
    transition: opacity $transition-duration ease;

    @include mq.mq($from: xl) {
      margin: 0 1rem;
    }

    img {
      margin: 0;
    }
  }

  .slide:not(.current) {
    cursor: pointer;
  }

  .caption {
    align-items: center;
    background: variables.color("gray", 900);
    display: flex;
    justify-content: center;
    min-height: 3rem;
    padding: 1rem;

    p {
      margin: 0;
    }
  }

  .thumbnail-container {
    display: none;
    flex: 0 0 8rem;
    height: 8rem;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;

    @include mq.mq($from: md) {
      display: block;
    }

    @include mq.mq($from: xl) {
      margin: 0 4.5rem;
      overflow-x: hidden;
    }
  }

  .thumbnails {

    display: flex;
    margin-right: 1.5rem;
    transition: $image-transition;
    @include overflow.overflow(scroll, x);

    @include mq.mq($from: md) {
      display: flex;
    }

    @include mq.mq($from: xl) {
      justify-content: center;
    }
  }

  .thumbnail {
    cursor: pointer;
    height: 5rem;
    margin: 1.5rem 0.75rem;
    min-width: 8.5rem;
    opacity: 0.5;
    position: relative;

    &:hover {
      opacity: 1;
    }

    &.current {
      &::after {
        background-color: variables.color(link, color);
        bottom: 0;
        content: "";
        height: 0.5rem;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .current {
    opacity: 1;
  }
}
