@use "../../../../../assets/stylesheets/typography/fonts";
@use "../../../../../assets/stylesheets/typography/h";
@use "../../../../../assets/stylesheets/utilities/borders";
@use "../../../../../assets/stylesheets/mq";

.quick-links {
  $separator: borders.$border-200;
  $spacing-sm: 1rem;
  $spacing-md: 1.5rem;

  margin-bottom: -$spacing-sm !important;

  @include mq.mq($from: md) {
    margin-bottom: -$spacing-md !important;
  }

  %h2 {
    margin-bottom: 0;
  }

  h3 {
    @extend %font-sans-normal-700;
  }

  h3,
  h5 {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  .link-container {
    display: flex;
    flex-wrap: wrap;
  }

  .links {
    border-bottom: $separator;
    flex: 0 0 100%;

    &:last-child {
      border-bottom: 0;
    }

    @include mq.mq($from: sm) {
      flex-basis: 50%;

      &:nth-child(n+3) {
        border-bottom: 0;
      }

      &:nth-child(even) {
        .separator {
          padding-left: $spacing-sm;
        }
      }

      &:nth-child(odd) {
        .separator {
          border-right: $separator;
          padding-right: $spacing-sm;
        }
      }

      .separator {
        height: calc(100% - #{$spacing-sm * 2});
        margin: $spacing-sm 0 !important;
      }
    }

    @include mq.mq($from: md) {

      &:nth-child(even) {
        .separator {
          padding-left: $spacing-md;
        }
      }

      &:nth-child(odd) {
        .separator {
          padding-right: $spacing-md;
        }
      }

      .separator {
        height: calc(100% - #{$spacing-md * 2});
        margin: $spacing-md 0 !important;
      }
    }

    @include mq.mq($from: lg) {
      border-bottom: 0;
      flex-basis: 25%;

      &:first-child {
        .separator {
          padding-left: 0;
        }
      }

      &:last-child {
        .separator {
          border-right: none;
          padding-right: 0;
        }
      }

      .separator {
        border-right: $separator;
        padding: 0 $spacing-md;
      }
    }
  }
}
