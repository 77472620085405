@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/utilities/borders";
@use "../../../../../assets/stylesheets/variables";

footer {
  .footer-issuer,
  .footer-bondlink {
    padding-top: 2rem;
    width: 100%;
  }

  .footer-issuer {
    @extend .accent-border-top;
    background-color: var(--accent-2-100);
    margin-bottom: 0;
    margin-top: 2rem;

    .footer-block {
      margin-bottom: 2rem;
    }
  }

  h5 {
    margin-bottom: 1rem;
    margin-top: 0;
  }

  .btn {
    margin-top: 0;
  }

  .btn-social {
    svg {
      color: variables.color("white"); // stylelint-disable-line
      height: 100%;
      width: 100%;
    }
  }

  .footer-bondlink {
    background-color: variables.color("gray", 800);
    color: variables.color("gray", 200);
    margin: 0;

    h5 {
      color: variables.color("gray", 200);
    }

    .bondlink-logo {
      height: 1.75rem;
      margin-bottom: 0;
      margin-left: 0.5rem;
      min-width: 10rem; //set min-width for IE11 quirks with loading SVGs as IMG src.
    }

    .btn-social {
      background-color: variables.color("gray", 200);
      margin-top: 0;

      &:active,
      &:hover {
        .icon {
          color: variables.color("gray", 800);
        }
      }

      &:active {
        background-color: variables.color("gray", 300);
      }

      &:hover {
        background-color: variables.color("white");
      }

      svg.icon {
        color: variables.color("gray", 800);
      }
    }

    .links {
      > * {
        color: variables.color("gray", 200);
        display: inline-block;
        margin: 0 0.75rem 2rem;

        &:hover {
          color: variables.color("gray", 300);
        }

        @include mq.mq($from: sm) {
          &:first-child {
            margin-left: 0;
          }
        }
      }

      a,
      button {
        text-decoration: underline;
      }
    }
  }
}
