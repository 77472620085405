@use "../functions/breakpoints";
@use "../functions/text";
@use "../variables";

// NOTE: If changing this make sure to change ParticipantRow.tsx
$participant-row-breakpoints: ("lg", "xl");

.participant-row {
  border-bottom: 0.125rem solid variables.color("gray", 300);

  &:last-child {
    border-bottom: none;
  }

  @each $bp in $participant-row-breakpoints {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class("break", $bp, "") {
        .participant-logo {
          max-height: 3.75rem;
          max-width: 5rem;
        }
        .contact-info {
          > div {
            &:not(:last-child){
              border-right: 0.125rem solid variables.color("gray", 300);
              padding-bottom: 0;
              padding-right: 1rem;
            }
            &:not(:first-child) {
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  .contact-info {
    margin-bottom: 0;
    > div {
      margin-bottom: 0;
      &:not(:last-child){
        padding-bottom: 0.5rem;
      }
    }
  }
}

.participant-logo {
  margin-bottom: 0;
  max-width: 4rem;
  object-fit: contain;
  object-position: top;
}

.common-participant-modal {
  .card {

    height: 6rem;
    @include text.text-sizing(0.875rem, 1.25rem);
  }

  .form-input {
    margin-bottom: 0;
  }
}
