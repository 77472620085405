@use "../functions/text";
@use "../typography/a";
@use "../typography/fonts";
@use "../variables";
@use "../mq";

$faux-border: deep-map-get(variables.$z-indexes, base);
$faux-bullet: $faux-border + 1;
$event-border-left: 0.25rem;

$dot-size-xs: 1.25rem;
$dot-size-md: 1.5rem;
$icon-size-xs: 2.5rem;
$icon-size-compact: 2.75rem;
$icon-size-md: 3rem;

$icon-right-space: 0.5rem;

%dot-icon-circle-shadow {
  box-shadow: 0 0.125rem 0.375rem 0 rgba(0, 0, 0, 0.12);
}

.activity-meta {
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  @include text.text-sizing(0.75rem, 1rem);

  .meta-row {
    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
    .meta-key {
      color: variables.color("teal", 700);
      flex-shrink: 0;
      margin-right: 0.5rem;

      &.width-large {
        width: 4.5rem;
      }

      &.width-small {
        width: 2.9375rem;
      }
    }
    .meta-value {
      color: variables.color("gray", 600);
    }
  }
}

.event-list {
  padding-top: 1rem;
}

.event-container {
  &:last-child {
    .event-large,
    .event-small {
      border-color: transparent;
      padding-bottom: 0;
    }
  }

  > .date {
    width: 5.75rem;
    word-break: normal;

    &:first-child {
      text-align: end;
    }

    @include mq.mq($from: md) {
      padding-top: 0.125rem;
    }
  }

  .event-date {
    margin-left: calc(variables.$icon-base-size + a.$icon-link-icon-spacing);
  }
}

@mixin event-margin-padding($icon-size) {
  margin-left: ($icon-size * 0.5) - ($event-border-left * 0.5);
  padding-left: ($icon-size * 0.5) - ($event-border-left * 0.5) + $icon-right-space;
}

.event-small,
.event-large {
  border-left: $event-border-left solid variables.color("gray", 400);
  flex: 1 1 0;
  padding-bottom: 2rem;
  position: relative;

  .details {
    align-items: flex-start !important;
    flex-direction: column;
    margin-bottom: 0;
  }
}

@mixin dot-styles($dot-size) {
  border-radius: $dot-size * 0.5;
  height: $dot-size;
  margin-left: -($dot-size * 0.5) - ($event-border-left * 0.5);
  width: $dot-size;
}

.event-small {
  @include event-margin-padding($dot-size-xs);

  &::before {
    @extend %dot-icon-circle-shadow;

    background-color: var(--accent-2-700);
    border: 0.25rem solid variables.color("white");
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    z-index: $faux-bullet;
    @include dot-styles($dot-size-xs);

    @include mq.mq($from: md) {
      @include dot-styles($dot-size-md);
    }
  }

  @include mq.mq($from: md) {
    @include event-margin-padding($dot-size-md);
  }
}

@mixin icon-circle-styles($icon-size) {
  height: $icon-size;
  left: -($icon-size * 0.5) - ($event-border-left * 0.5);
  width: $icon-size;
}

.event-large {
  @include event-margin-padding($icon-size-xs);

  @include mq.mq($from: md) {
    @include event-margin-padding($icon-size-md);

    &.compact {
      @include event-margin-padding($icon-size-compact);
    }
  }

  .activity-header {
    @extend %font-sans-normal-500;
    color: var(--accent-2-700);
    flex-direction: column;
    justify-content: space-between;
    @include mq.mq($from: sm) {
      flex-direction: row;
    }

    .activity-title {
      margin-bottom: 0;
    }
  }

  > .icon-circle {
    @extend %dot-icon-circle-shadow;

    align-items: center;
    border-radius: 1.625rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    position: absolute;

    &.compact {
      top: 0;
    }
    @include mq.mq($until: md){
      @include icon-circle-styles($icon-size-xs);

      .icon {
        height: 1.5rem;
        width: 1.5rem;
      }
    }

    @include mq.mq($from: md){
      @include icon-circle-styles($icon-size-md);

      &.compact {
        @include icon-circle-styles($icon-size-compact);

        .icon {
          height: 1.75rem;
          width: 1.75rem;
        }
      }

      .icon {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  .content p {
    margin: 0;
  }
  .content-body.collapsed {
    p:last-of-type {
      height: 3.75rem;
      overflow: hidden;
      @include mq.mq($from: md){
        height: 4.5rem;
      }
    }
  }
  .expandable-link {
    align-self: flex-start;
  }
}
