@use "sass:map";
@use "../variables";
@use "../mq";
@use "../functions/transitions";
@use "../utilities/shadows";
@use "buttons";
@use "card";

@mixin icon-sizing($icon-size) {
  .icon {
    height: $icon-size;
    width: $icon-size;
  }
}

%subscription-drop-shadow {
  &:hover,
  &:active {
    .icon-bookmark-delete-filled-2color,
    .icon-bookmark-checked-2color,
    .icon-bookmark-add-2color {
      @extend %filter-drop-shadow-icon-white-3;
    }
  }

  .icon-bookmark-delete-filled-2color,
  .icon-bookmark-checked-2color,
  .icon-bookmark-add-2color {
    @extend %filter-drop-shadow-icon-white-1;
  }
}

.subscription-icon-card-container {
  $right: 0.5rem - 0.125rem;
  $top: -0.5rem - 0.125rem;

  position: absolute;
  right: $right;
  top: $top;
  z-index: deep-map-get(variables.$z-indexes, "dropdown");

  &.not-absolute {
    margin-right: -$right;
    margin-top: $top - card.$card-padding;
    position: initial;
    right: 0;
    top: 0;
  }

  &.issuer-feed-toggle {
    right: 0.5rem;
    top: -0.5rem;
    .subscription-icon-card {
      @include icon-sizing(2rem);
    }
  }
  // Context: Investor Portal Offering Calendar
  &.offering-calendar {
    .subscription-icon-card {
      @include mq.mq($until: md) {
        @include icon-sizing(2rem);
      }
    }
  }

  // Context: Bond Sale Calendar
  &.bond-sale-calendar {
    .subscription-icon-card {
      @include icon-sizing(2rem);
    }
  }
}

.subscription-icon-card {
  @extend  %subscription-drop-shadow;
  @include icon-sizing(3rem);

  &:focus {
    .icon-bookmark-delete-filled-2color,
    .icon-bookmark-checked-2color,
    .icon-bookmark-add-2color {
      @extend %focus-drop-shadow;
    }
  }
}

.add-to-subscription,
.remove-from-subscription {
  &:hover,
  &:active {
    span {
      border-color: transparent;
    }
  }
}

.btn-toggle-alerts,
.btn-toggle-bookmark,
.subscription-toggle-tooltip {
  .bl-tooltip-content {
    position: relative;
  }
  .bl-tooltip-content,
  .tooltip-content {
    white-space: nowrap;
    width: auto;
    @include icon-sizing(1.25rem);
    .icon {
      margin: 0;
      margin-right: 0.125rem;
    }
  }
}

@mixin btn-toggle-subscription-states($show, $hide) {
  #{$show} {
    display: inline;
  }

  @each $x in $hide {
    #{$x} {
      display: none;
    }
  }
}

%subscription-hover-styles {
  .toggle-subscription {
    transform: scale(variables.$scale);
    &[data-checked="true"][data-hoverable="true"]:not([disabled]) {
      @include btn-toggle-subscription-states(".delete-toggle", (".add-toggle", ".checked-toggle"));
    }
  }
  .icon-toggle-subscription {
    &[data-checked="false"] {
      @include buttons.icon-colors(buttons.$icon-two-color-primary-hover);
    }
    &[data-checked="true"][data-hoverable="true"] {
      @include buttons.icon-colors(buttons.$icon-two-color-danger-hover);
      &[disabled] {
        @include buttons.icon-colors(buttons.$icon-two-color-disabled);
      }
    }
    &[disabled] {
      @include buttons.icon-colors(buttons.$icon-two-color-disabled);
    }
  }
  .subscription-toggle-tooltip {
    .remove-from-subscription {
      color: map.get(buttons.$icon-two-color-danger-hover, primary);
    }
    .add-to-subscription {
      color: map.get(buttons.$icon-two-color-primary-hover, primary);
    }
  }
  .btn-toggle-subscription {
    &[data-hoverable="true"][data-checked="true"] {
      @extend .btn-danger;
    }
  }
}

%subscription-active-styles {
  .icon-toggle-subscription {
    &[data-checked="false"] {
      @include buttons.icon-colors(buttons.$icon-two-color-primary-active);
    }
    &[data-checked="true"][data-hoverable="true"] {
      @include buttons.icon-colors(buttons.$icon-two-color-danger-active);
    }
    &[disabled] {
      @include buttons.icon-colors(buttons.$icon-two-color-disabled);
    }
  }
  .subscription-toggle-tooltip {
    .remove-from-subscription {
      color: map.get(buttons.$icon-two-color-danger-active, primary);
    }
    .add-to-subscription {
      color: map.get(buttons.$icon-two-color-primary-active, primary);
    }
  }
}

.subscription-toggle {
  &:hover {
    @extend %subscription-hover-styles;
  }
  &:active {
    @extend %subscription-active-styles;
  }
  &.tooltip-open {
    @extend %subscription-hover-styles;
    &:active {
      @extend %subscription-active-styles;
    }
  }
}


.subscription-toggle-tooltip {
  .remove-from-subscription {
    color: map.get(buttons.$icon-two-color-danger-hover, primary);
  }
}

.toggle-subscription {
  @extend %transition-base;
  margin-bottom: auto;
  &:hover {
    transform: scale(variables.$scale);
  }
  &[data-checked="false"] {
    @include btn-toggle-subscription-states(".add-toggle", (".checked-toggle", ".delete-toggle"));
  }

  &[data-checked="true"] {
    @include btn-toggle-subscription-states(".checked-toggle", (".add-toggle", ".delete-toggle"));

    &[data-hoverable="true"] {
      &:not([disabled]):hover {
        @include btn-toggle-subscription-states(".delete-toggle", (".add-toggle", ".checked-toggle"));
      }
    }
  }
}

.icon-toggle-subscription {
  @include buttons.icon-colors(buttons.$icon-two-color-primary);
  &:focus-visible {
    .tooltip-trigger,
    .tooltip-target  {
      .icon {
        filter: none !important;
      }
    }
  }
  &.add-drop-shadow {
    @extend  %subscription-drop-shadow;
  }

  &[disabled] {
    @extend %btn-disabled-no-transform;
    @include buttons.icon-colors(buttons.$icon-two-color-disabled);
  }
  .icon {
    .background {
      /* linter thinks color: variables.color("white") is equal to color: white */
      /* stylelint-disable-next-line declaration-property-value-disallowed-list */
      color: variables.color("white");
      fill: variables.color("white");
    }
  }
}

.subscription-count-container {
  color: map.get(buttons.$icon-two-color-teal, primary);
  display: flex;

  &.no-subscriptions {
    @extend .icon-2-color-disabled;
    color: map.get(buttons.$icon-two-color-disabled, primary);
  }

  .tooltip-trigger {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
  }
}


.btn-toggle-subscription {
  &[data-hoverable="true"] {
    &[data-checked="true"] {
      &:hover {
        @extend .btn-danger;
      }
    }
  }
}
