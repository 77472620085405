@use "../variables";
@use "../mq";

.faq-item {
  cursor: pointer;
  display: flex;
  margin-bottom: 1rem;
  .faq-content {
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .expandable {
      cursor: auto;
      height: 0;
      overflow: hidden;
      &.open {
        height: auto;
      }
    }
  }
  .icon-button {
    background-color: var(--accent-1-700);
    border-radius: 5rem;
    display: flex;
    flex-shrink: 0;
    height: 1.75rem;
    margin-right: 0.5rem;
    position: relative;
    width: 1.75rem;

    &:hover {
      background-color: var(--accent-1-500);
    }

    &:active {
      background-color: var(--accent-1-900);
    }

    i {
      margin: auto !important;
    }

    svg {
      fill: variables.color("white");
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

.faq-table-container .faq-table {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq.mq($from: md) {
    margin-bottom: 2rem;
  }
}
