@use "../functions/overflow";
@use "../functions/shadows";
@use "../functions/text";
@use "../typography/a";
@use "../typography/fonts";
@use "../variables";

%tooltip-show {
  background-color: variables.color("white");
  filter: drop-shadow(0 0.25rem 0.75rem rgba(variables.color("black"), 0.24));
  opacity: 1;
  padding: deep-map-get(variables.$spacers, "05");
  visibility: visible;
}

// Generates rotation definitions for tooltip 'triangle'
@mixin x-placement($attr-dir, $prop-dir, $rotation) {
  &[data-popper-placement*="#{$attr-dir}"] {
    .arrow {
      @if ($prop-dir) {
        #{$prop-dir}: -0.5rem;
      }
    }
    .arrow::before {
      transform: rotate($rotation);
    }
  }
}

/**
Container class for tooltip target and content.
- Target element should be wrapped with div class="tooltip".
- Content element should be next adjacent sibling to target element.
*/
%tooltip-styles {
  background: variables.color("white");
  border-radius: variables.$border-radius-1;
  color: variables.color("gray", 800);
}
.tooltip {
  overflow: visible;
  position: relative;

  &-trigger {
    @extend %anchor-focus-styles-focus-visible;

    &.inverted {
      &:focus-visible:not(.no-focus) {
        @extend %anchor-focus-styles-inverted;
      }
    }
  }

  &-content {
    @extend %font-sans-normal-400;
    @extend %tooltip-styles;

    opacity: 0;
    position: absolute;
    text-align: left;
    transition: opacity variables.$transition-base-duration, transform variables.$transition-base-duration;
    visibility: hidden;
    width: 18rem;
    z-index: deep-map-get(variables.$z-indexes, "dropdown");

    @include x-placement("top", null, 135deg);
    @include x-placement("right", "left", -135deg);
    @include x-placement("bottom", "top", -45deg);
    @include x-placement("left", "right", 45deg);
    @include text.text-sizing(variables.$tooltip-font-size, variables.$tooltip-line-height);

    &[data-popper-placement*="end"] {
      .arrow {
        left: unset !important;
        position: absolute;
        right: 1rem;
      }
    }

    // Make the tooltip appear when the show class is present, delayed 200ms
    &.show {
      @extend %tooltip-show;
      .arrow::before {
        visibility: visible;
      }
    }

    // Create triangle properties for pointer based on direction.
    .arrow,
    .arrow::before {
      background-color: variables.color("white");
      content: "";
      height: 1rem;
      position: absolute;
      width: 1rem;
      z-index: deep-map-get(variables.$z-indexes, below-all);
    }

    .arrow {
      visibility: hidden;
    }

    // Overrides spacing defined in typography/_list.scss to match component's design spec
    ul li {
      margin-left: 0;
    }
  }

  // Include border for IE11 instead of filtered drop shadow.
  :-ms-fullscreen,
  :root & {
    .tooltip-content {
      border: 1px solid variables.color("gray", 200);
      .arrow,
      .arrow::before {
        border: 1px solid transparent;
        border-right-color: variables.color("gray", 200);
        border-top-color: variables.color("gray", 200);
      }
    }
  }
}

.debt-profile-tooltip-container {
  max-width: 22rem;
  min-width: 15rem;
}

.analytics-chart-tooltip-container {
  max-width: 18rem;
  min-width: 8rem;

  .tooltip-title {
    align-items: center;
    border-radius: 0.125rem;
    display: flex;
    height: 1.25rem;
    letter-spacing: 0.5px;
    margin-bottom: 0.5rem;
    padding: 0 0.25rem;
    width: fit-content;
    span {
      white-space: nowrap;
    }
  }
}

.chart-tooltip-group:not(.tt-is-safari) {
  filter: drop-shadow(0 0 0.5rem rgba(variables.color("black"), 0.24));
}

.safari-tooltip-container {
  @include overflow.overflow(auto, "x");
}

.chart-tooltip {
  @extend %tooltip-styles;
  font-size: 0.875rem;
  margin: 1rem;
  padding: 1rem;
  width: max-content;

  &.tt-is-safari {
    @include shadows.box-shadow(2);
  }

  div {
    margin-bottom: deep-map-get(variables.$spacers, "025");
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .arrow {
    background: transparent;
    border: 1rem solid transparent;
    height: 1rem;
  }
  .arrow-left {
    border-right-color: variables.color("white");
    margin-left: -3rem;
    position: absolute;
  }
  .arrow-right {
    border-left-color: variables.color("white");
    margin-right: -2rem;
    position: absolute;
    right: 0;
  }
  .arrow-bottom {
    border-bottom-color: variables.color("white");
    bottom: 0;
    margin-bottom: -2rem;
    position: absolute;
    transform: rotate(180deg);
  }
}
.chart-tooltip-value-row {
  display: flex;
  justify-content: flex-start;
  .box-one-rem {
    margin-right: deep-map-get(variables.$spacers, "025");
  }
  .legend {
    align-items: center;
    display: flex;
    padding-right: deep-map-get(variables.$spacers, "025");
    width: 45%;
  }
  .value {
    width: 55%;
  }
}
