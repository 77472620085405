@use "../variables";

.icon-leaf {
  color: variables.color("lime", 700);
}

// prevent color from being overridden by link styles on investor portal
.tooltip-trigger {
  .icon-leaf {
    color: variables.color("lime", 700) !important;
  }
}
