@use "sass:map";
@use "../components/markdown-table";
@use "../functions/headers";
@use "../utilities/clearfix";
@use "../variables";

.md-to-html {
  &:after {
    @extend .clearfix;
  }
  &.react {
    table {
      @include markdown-table.markdown-table-styles(xs);
    }
  }

  &.last-child-mb-0  > :last-child  {
    margin-bottom: 0;
  }

  .accent-border-top {
    overflow: hidden;
  }

  hr {
    border-bottom: 1px solid variables.color("gray", 300);
    border-left: none;
    border-right: none;
    border-top: none;
    clear: both;
    height: 1rem;
    margin-bottom: 1rem;
  }

  hr[data-page-break="true"] {
    height: 0;
    margin-bottom: 0;
    visibility: hidden;
  }

  > {
    @include headers.h-list(map.keys(variables.$h-props)) {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  @at-root .truncate &,
  &.truncate,
   {
    ol li::before {
      content: none;
    }

    hr,
    img,
    table {
      display: none;
    }

    * {
      font-size: inherit;
      line-height: inherit;
      margin: 0;
      padding: 0;
    }
  }
}
