@use "../mq";
@use "../functions/icon";
@use "../functions/transitions";
@use "../variables";

%accordion-pseudo {
  left: 0;
  position: absolute;
  top: 0;
  @include icon.icon-circle((xs: 1.5rem, md: 1.75rem), (xs: 0.25rem, md: 0.25rem), (xs: 0.875rem, md: 1rem));
}

.accordion-container {
  &:hover {
    .accordion {
      .accordion-button {
        background-color: var(--accent-1-500);
      }
    }
  }

  &:active {
    .accordion {
      .accordion-button {
        background-color: var(--accent-1-900);
      }
    }
  }

  .accordion {

    cursor: pointer;
    margin-top: 1.5rem;
    padding-left: 2.25rem;
    position: relative;

    &.show {
      .accordion-button-icon{
        transform: rotate(-180deg);
      }
    }

    &.open {
      .content {
        max-height: 9999px;
      }
    }

    @include mq.mq($from: md) {
      padding-left: 2.75rem;
    }

    .accordion-button {
      @extend %accordion-pseudo;

      background-color: var(--accent-1-700);
      content: "";
      display: block;
    }

    .accordion-button-icon{
      @extend %transition-base;
      @extend %accordion-pseudo;
      transform: rotate(0deg);
    }

    h5 {
      color: deep-map-get(variables.$h-props, h5, xs, color);

      line-height: 1.5rem;
      margin-top: 0;

      @include mq.mq($from: md) {
        line-height: 1.75rem;
      }
    }

    .content {
      @extend %transition-base;

      max-height: 0;
      overflow: hidden;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      &:hover {
        cursor: initial;
      }
      .show & {
        overflow: visible;
      }
    }
  }
}
