@use "../mq";

$error-header-width: 56rem;
$error-text-width: 40rem;

.error-page {
  flex: 1 0 auto;

  &:not(.sites-error-page) .content-container {
    margin-top: 10rem;
  }

  .error-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 1rem;
    text-align: center;

    @include mq.mq($from: sm) {
      margin: 4rem 1rem 2rem 1rem;
    }

    p.large {
      margin-bottom: 1.5rem;
      padding: 0 1rem;
      text-align: center;
    }
  }

  .error-header {
    max-width: $error-header-width;
  }

  .error-text {
    max-width: $error-text-width;
  }
}
