@use "../../../../../assets/stylesheets/mq";

.program-image {
  flex: 1 0 8rem;
  height: 5rem;

  @include mq.mq($from:md){
    flex: 1 0 16rem;
    height: 12.5rem;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
