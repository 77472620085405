@use "../typography/a";
@use "../utilities/borders";
@use "../utilities/display";
@use "../utilities/grid";
@use "../variables";
@use "../mq";

$jump-links-border-width: 0.25rem;
$jump-links-margin-bottom: 1rem;
$jump-links-padding-y: calc($jump-links-margin-bottom / 2);
$jump-links-margin-left: 1.25rem;
$jump-links-padding-right: 1rem;

.jump-links {
  border-top: borders.$border-200;
  margin-bottom: 1.5rem;
  padding-top: 0.75rem;

  .h4,
  h4 {
    display: block;
    margin-bottom: 0.75rem;
  }

  .h5,
  h5 {
    display: none;
  }

  a {
    display: inline-block;
  }

  .jump-link {
    margin-bottom: $jump-links-margin-bottom;
    width: fit-content;
  }

  @include mq.mq($from: md) { // stylelint-disable-line order/order
    border-top: none;
    padding-top: 0;

    .jump-link {
      display: flex;
      margin-bottom: 0;

      &::before {
        border-left: $jump-links-border-width solid transparent;
        content: "";
        margin-bottom: $jump-links-padding-y;
        margin-left: -(variables.$grid-border-width);
        margin-top: $jump-links-padding-y;
        z-index: deep-map-get(variables.$z-indexes, base);
      }

      &:hover::before {
        border-left-color: variables.color(link, color);
      }

      &:first-child {
        &::before {
          margin-top: 0;
        }

        a {
          padding-top: 0;
        }
      }

      &:last-child {
        &::before {
          margin-bottom: 0;
        }

        a {
          padding-bottom: 0;
        }
      }

      &.current {
        &::before {
          border-left-color: variables.color("black");
        }

        a {
          @include a.anchor-state-styles(variables.color("black"), variables.color("gray", 900), variables.color("gray", 700));
        }
      }

      a {
        @extend %anchor-md-no-decoration;
        padding:
          $jump-links-padding-y
          $jump-links-padding-right
          $jump-links-padding-y
          $jump-links-margin-left;
      }
    }

    .h4,
    h4 {
      display: none;
    }

    .h5,
    h5 {
      margin: 0;
    }
  }

  @each $bp, $spacing in grid.$grid-right-margins {
    @include mq.mq($from: $bp) {
      margin-left: -$spacing;
    }
  }
}

.jump-links-0 {
  display: none;

  @include mq.mq($from: md) {
    display: block;
  }
}

.sidebar-link {
  padding-top: 0.5rem;

  &.sidebar-mini {
    border-top-width: 0;
    margin-top: 0;
    padding-top: 0;
  }

  .h4 {
    margin-bottom: 0.25rem;
  }

  .btn {
    margin-top: 0;
  }
}

// For the new Sidebar Links Component so we don't mess with the existing styles depended on by Scala
nav.react-jump-links {
  height: fit-content;
}

.react-jump-links {
  &-empty {
    @extend .d-none-until-md;
  }

  @include mq.mq($from: md) { // stylelint-disable-line order/order
    border-top: none;
    padding-top: 0;

    .h5,
    h5 {
      display: block;
    }
  }
}
