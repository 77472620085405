@use "../variables";
@use "../mq";
@use "../typography/fonts";
@use "../utilities/colors";
@use "../utilities/gradients";


.content-type-details {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;

  &.accent {
    &-green {
      .date {
        color: variables.color("teal", 700);
      }
    }
    &-red {
      .date {
        color: variables.color("magenta", 700);
      }
    }
    &-accent-2 {
      .date {
        color: var(--accent-2-700);
      }
    }
  }

  &.sites-featured-item {
    .date {
      color: var(--accent-2-700);
      margin: 0;
    }
    .badge {
      @extend .inverted;
      @extend .accent-2-700-bg;
      display: flex;
      float: left;
      padding: 0.25rem 0.5rem;

      span {
        margin-left: 0.25rem;
      }
    }
  }

  @include mq.mq($from: sm) {
    flex-wrap: nowrap;
  }

  .modal &,
  .card & {
    margin-bottom: 0.75rem;
  }

  .icon-circle {
    flex-shrink: 0;
    margin-right: 0.75rem;
  }

  .date {
    margin: 0;
  }

  .title-container {
    flex-basis: 80%;

    @include mq.mq($from: sm) {
      flex-basis: auto;
    }

    .icon-circle {
      height: 2.5rem;
      padding: 0.5rem;
      width: 2.5rem;

      @include mq.mq($from: md) {
        height: 2.75rem;
        width: 2.75rem;
      }

      .icon {
        height: 1.5rem;
        width: 1.5rem;

        @include mq.mq($from: md) {
          height: 1.75rem;
          width: 1.75rem;
        }
      }
    }
  }

  .content-type {
    @extend %font-sans-normal-700;
    color: variables.color("black");
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;

    @include mq.mq($from: md) {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  .roadshow-tag {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-start;
    order: 1;

    @include mq.mq($from: sm) {
      flex-basis: auto;
      flex-grow: 1;
      justify-content: flex-end;
      order: 0;
    }

    .badge {
      margin-right: 3rem;
      margin-top: 1rem;
      @include mq.mq($from: sm) {
        margin-top: 0;
      }
    }
  }

  &-badge {
    @extend .inverted;
    align-items: center;
    display: flex;
    float: left;
    justify-content: center;
    padding: 0.25rem 0.5rem;

    &.bg {
      &-green {
        @extend .accent-2-700-bg;
      }
      &-white {
        background-color: variables.color("white");
      }
      &-bl-gradient {
        @extend .bl-gradient-horizontal;
      }
      &-red {
        background-color: variables.color("magenta", 700);
      }
    }

    span {
      margin-left: 0.25rem;
    }
  }
}
