@use "../../utilities/borders";

.sidebar-section {
  padding-top: 1rem;
  & + & {
    border-top: borders.$border-300;
  }
  &:first-child {
    padding-top: 0;
  }
}
