@use "../../mq";
@use "../../typography/fonts";
@use "../../utilities/borders";

.bid-submission-form {
  .field-group {
    width: 100%;
  }

  &-question,
  &-document {
    border-top: borders.$border-100;
    padding-top: 0.875rem;

    label {
      @extend %font-size-default;
    }
  }

  &-question {
    .custom-indicator-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include mq.mq($until: sm) {
        flex-direction: column;

        .custom-indicator-label {
          margin-bottom: 1rem;
          margin-right: 0;
        }
      }
      .custom-indicator-label {
        margin-right: 1.5rem;
      }

      .form-input {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        &:not(.textarea) {
          width: initial;
        }

        .custom-indicator {
          margin-top: 0;
          width: 4.5rem;
        }
      }
      .btn-link {
        align-self: start;
        font-size: 1rem;
        white-space: nowrap;
      }
    }
  }

  &-document {
    .form-input {
      display: flex;
      justify-content: space-between;

      @include mq.mq($until: sm) {
        flex-direction: column;
      }

      .custom-indicator {
        margin-top: 0;
        width: 4.5rem;
      }

      .btn-dropzone-wrapper,
      .upload-status-wrapper {
        display: flex;
        height: fit-content;
        margin-top: 1rem;
        min-width: 10.5rem;
        width: fit-content;

        @include mq.mq($from: sm){
          justify-content: end;
          margin-left: 1rem;
          margin-top: 0;
        }
      }
    }
  }
}
