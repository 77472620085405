@use "../variables";
@use "sass:math";
@use "../mq";
@use "../functions/transitions";
@use "../utilities/shadows";

.image-gallery-container {
  &.multi {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;

    @include mq.mq($from:md){
      grid-template-columns: repeat(3, 1fr);
    }
    @include mq.mq($from:lg){
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.image-gallery-preview {
  @extend %box-shadow-1;
  @extend %transition-base;

  background-size: cover;
  height: 0;
  margin-bottom: 1rem;
  padding-top: math.div(9, 16) * 100%;
  position: relative;
  width: 100%;

  &:hover {
    @extend %box-shadow-3;

    cursor: pointer;
    transform: scale(variables.$scale);

    .gallery-link {
      background: linear-gradient(to bottom, rgba(variables.color("black"), 0) 0%, variables.color("black") 100%);
    }
  }

  img {
    left: 0;
    position: absolute;
    top: 0;

    &:not([src]) {
      display: none;
    }
  }

  .gallery-link {
    @extend %transition-base;

    background: linear-gradient(to bottom, rgba(variables.color("black"), 0) 0%, rgba(variables.color("black"), 0.75) 100%);
    bottom: 0;
    height: 4rem;
    padding-left: 0.5rem;
    padding-top: 2.25rem;
    position: absolute;
    width: 100%;

    .icon {
      margin-right: 0.25rem;
    }

    h4 {
      display: inline-block;
      margin: 0;
    }
  }
}

@include mq.mq($from: md) { //stylelint-disable-line order/order
  .float-md-right {
    .image-gallery-preview {
      margin-bottom: 0;
    }
  }
}

.image-container {
  overflow: hidden;
  position: relative;
  .image-count-overlay {
    background: radial-gradient(ellipse 100% 100% at 100% 100%, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    position: absolute;
    width: 100%;
    .image-count {
      bottom: 0.25rem;
      display: flex;
      line-height: 1.5rem;
      position: absolute;
      right: 0.25rem;
      .icon-image {
        height: 1.5rem;
      }
    }
  }
}
