@use "../functions/icon";
@use "../functions/overflow";
@use "../functions/overlay";
@use "../functions/shadows" as shadowFns;
@use "../functions/text";
@use "../functions/transitions";
@use "../typography/fonts";
@use "../utilities/grid";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";

.filters-root-background {
  backdrop-filter: blur(0.25rem);
  @include overlay.overlay(0, 0.5, filters-background);
}

$filters-root-container-width: 17.5rem;
$filters-root-container-padding: 1rem;
$filters-root-container-offset: $filters-root-container-width + ($filters-root-container-padding * 2);
// There is javascript that depends on this height called scrollButtonHeight = 40 in FiltersLayout
// If you update this location update the other one as well
$filters-scroll-button-height: 2.5rem;
$filters-scroll-button-offset: 0.5rem;

.filters-root-container {
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: deep-map-get(variables.$z-indexes, below-all);
  @include overflow.overflow(auto, y);
  &.show {
    z-index: deep-map-get(variables.$z-indexes, filters-container);
    .filters-root-filter {
      transform: translate(0);
      z-index: deep-map-get(variables.$z-indexes, filters);
    }
  }
  .filters-root-filter {
    @extend %transition-base;
    background-color: variables.color("white");
    height: fit-content;
    min-height: 100%;
    padding: $filters-root-container-padding;
    position: relative;
    transform: translate(-$filters-root-container-offset);
    width: $filters-root-container-width;
    .filters-close-x {
      color: variables.color(link, color);
      &:hover,
      &:active {
        color: variables.color(link, hover, color);
      }
    }
  }
}

$filter-scroll-button-icon-size: 1.5rem;
$filter-scroll-button-border-radii: calc($filters-scroll-button-height / 2);
.filter-scroll-button {
  @extend %box-shadow-1;
  @extend %transition-base;
  background-color: variables.color("white");
  border: none;
  cursor: pointer;
  display: flex;
  left: calc(($filters-root-container-width / 2) - ($filters-scroll-button-height / 2));
  position: fixed;
  transform: translate(0);
  z-index: deep-map-get(variables.$z-indexes, filters) + 1;
  @include icon.icon-circle((xs: $filters-scroll-button-height, md: $filters-scroll-button-height), (xs: 0, md:0), (xs: $filter-scroll-button-border-radii, md:$filter-scroll-button-border-radii));

  &:hover,
  &:active {
    .icon {
      color: variables.color(link, hover, color);
    }
  }
  &.bottom {
    bottom: $filters-scroll-button-offset;
  }
  &.top {
    top: $filters-scroll-button-offset;
    .icon {
      transform: rotate(180deg);
    }
  }
  i {
    margin: auto;
  }
  .icon {
    color: variables.color(link, color);
    height: $filter-scroll-button-icon-size;
    width: $filter-scroll-button-icon-size;
  }
}

$filter-toggle-margin-bottom: 0.375rem;
$filter-border-bottom-width: 0.125rem;

.filters {
  margin-bottom: 1.5rem;
  width: 100%;

  &.show {
    .filter-options-container {
      border-bottom-style: solid;
    }
  }

  &.open {
    .filter-toggle {
      .icon {
        transform: rotate(-180deg);
      }
    }
  }

  .filter-toggle {
    border-bottom: $filter-border-bottom-width solid variables.color("gray", 300);
    color: variables.color(link, color);
    cursor: pointer;
    margin-bottom: $filter-toggle-margin-bottom;
    padding-bottom: $filter-toggle-margin-bottom;
    width: 100%;

    span {
      @extend .large;
    }

    .icon {
      transition: transform variables.$transition-base-duration ease;
      vertical-align: text-bottom;
    }
  }

  .filter-options-container {
    @extend %transition-base;

    border-bottom-color: variables.color("gray", 300);
    border-bottom-width: $filter-border-bottom-width;
    margin-top: -$filter-toggle-margin-bottom;
    max-height: 0;
    overflow: hidden;

    &.checkbox-group {
      .form-input.custom-indicator {
        margin-left: 0.5rem;
      }
    }

    > .filter-options {
      padding-bottom: 1.5rem;
      padding-top: 0.625rem;
    }
  }
}

.filter-tabs-container {
  position: relative;
  .tippy-box.filter-popup-tooltip[data-placement=right] {
    >.tippy-arrow::before {
      $border-width: 1rem;
      border-right-color: variables.color("white");
      border-width: $border-width $border-width $border-width 0;
      left: -0.9625rem;
      top: 0.875rem;
    }
  }
}

.filter-tab-container {
  background-color: variables.color("gray", 100);
  position: relative;
  &:not(:last-child) {
    .filter-tab {
      margin-bottom: 0.125rem;
    }
  }
  .modal-background {
    display: none;
  }
}

.portal-table-header.mobile-activity-filters {
  @extend %container-spacing-negate;
  margin-bottom: 0;
  min-width: 100vw;
  .toolbar {
    @extend %container-padding;
    .btn-link {
      @extend %font-sans-normal-500;
      align-items: center;
      display: flex;
      margin-bottom: 0.5rem;
      width: max-content;
      @include text.text-sizing(1.125rem, 1.5rem);
    }
  }
  .checkbox-group {
    @extend %container-padding;
    background-color: variables.color("gray", 100);
    border-top: none;
    box-shadow: inset 0 0 0.375rem 0.125rem rgba(variables.color("black"), 0.12);
    margin-bottom: 0.5rem;
    margin-top: 0;
    &.transitioning {
      margin-bottom: 1rem;
    }
    &.transitioned {
      border-bottom: none;
      margin-bottom: 1rem;
    }
    .close-filters-button {
      display: none;
    }
  }
}

%filter-tab-shadow {
  box-shadow: 0 0 0.5rem 0 variables.color("blue", 700);
}

.filter-tab {
  align-items: flex-start;
  background-color: variables.color("gray", 100);
  border: 0.125rem solid transparent;
  border-radius: 0.125rem;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  text-align: start;
  width: 100%;

  // Base States
  &:hover {
    background-color: variables.color("blue", 100);
    .filter-title,
    .filters-applied {
      color: variables.color("blue", 500);
    }
  }
  &:focus {
    @extend %filter-tab-shadow;
    background-color: variables.color("white");
  }
  &:active {
    .filter-title,
    .filters-applied {
      color: variables.color("blue", 700);
    }
  }

  // Disabled State
  &:disabled {
    background-color: variables.color("gray", 100);
    pointer-events: none;
    .filter-title,
    .filters-applied {
      color: variables.color("gray", 500);
    }
  }

  &.open {
    border: 0.125rem solid variables.color("black");
    color: variables.color("black");
  }
  // Applied States
  &.applied {
    background-color: variables.color("blue", 700);
    border-color: transparent;
    &:hover {
      background-color: variables.color("blue", 500);
    }
    &:focus {
      @extend %filter-tab-shadow;
    }
    &:active {
      background-color: variables.color("blue", 700);
    }
    &:disabled {
      background-color: variables.color("gray", 500);
      .filter-title,
      .filters-applied {
        color: variables.color("gray", 200);
      }
    }

    &.open {
      border-color: variables.color("black");
    }
    .filter-title {
      @extend %font-sans-normal-500;
    }
    .filter-title,
    .filters-applied {
      color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    }
  }

  .filter-title {
    color: variables.color("gray", 800);
    @include text.text-sizing(1rem, 1.5rem);
  }

  .filters-applied {
    color: variables.color("gray", 500);
    text-align: left;
    @include text.text-sizing(0.875rem, 1.25rem);
  }
}

#filter-popup-modal-root {
  @include mq.mq($from: lg) {
    display: none;
  }

  .filter-children-container {
    padding: 0;
  }

  .filter-popup-header {
    .close-button {
      @include text.text-sizing(1rem, 1.5rem);

      .icon-button {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .modal-dialog {
    margin: 1rem auto;

    &.time-frame-filter {
      .modal-body {
        @include overflow.overflow(visible);
        @include mq.mq($from: sm) {
          @include overflow.overflow(scroll, y);
        }
      }
    }
    .modal-body {
      background-color: variables.color("white");
      border-radius: 0.25rem;
      max-height: calc(100vh - 2rem);
      padding: 1rem;
      @include overflow.overflow(scroll, y);
    }
  }
}

.filter-popup-header {
  .close-button {
    button {
      color: variables.color("blue", 700);
    }
  }
  .popup-header {
    .popup-title {
      @extend %font-sans-normal-700;
      color: variables.color("black");
      @include text.text-sizing(1.5rem, 2rem);
    }
    button {
      width: max-content;
      @include text.text-sizing(1rem, 1.5rem);
    }
  }
}

$filter-popup-border-radius: 0.25rem;

.filter-popup {
  max-height: calc(100vh - $filter-popup-border-radius);
  padding: 1rem;

  .form-input {
    margin-bottom: 0;
  }
  .filter-children-container {
    margin-bottom: 0.5rem;
    max-height: 27rem;
    @include overflow.overflow(auto, y);
  }
  .filter-type-header {

    align-items: baseline;
    color: variables.color("gray", 800);
    display: flex;
    flex-wrap: wrap;
    @include text.text-sizing(1rem, 1.5rem);
    .filter-type-dropdown {
      width: 4.5rem;
      .bl-react-select {
        &__control,
        &__control--is-focused {
          min-height: 1.5rem;
        }
      }
    }
  }
  .filter-popup-text {
    color: variables.color("gray", 800);
  }
  .search-note {
    @extend %font-sans-italic-400;
    color: variables.color("gray", 600);
    margin-bottom: 1rem;
    padding-top: 0.5rem;
    @include text.text-sizing(1rem, 1.5rem);
  }
  .search-input {
    &::placeholder {
      @extend %font-sans-italic-400;
    }
  }
  .empty-tab {
    text-align: center;
    @include text.text-sizing(1rem, 1.5rem);
  }
  :last-child {
    margin-bottom: 0;
  }
}

.filter-popup-tooltip {
  border-radius: $filter-popup-border-radius;
  display: none;
  width: 27rem;
  @include shadowFns.box-shadow(3);

  @include mq.mq($from: lg) {
    display: block;
  }

  .filter-popup {
    background-color: variables.color("white");
    border-radius: $filter-popup-border-radius;
  }
}

.filter-tabs-label {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

#filter-header + .filter-tabs-label {
  margin-top: 0;
}

.vertical-divider {
  height: 1rem;
  margin: auto 0.5rem;
  width: 1.5px;
}
