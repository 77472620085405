@use "sass:map";
@use "../../../../../assets/stylesheets/functions/breakpoints";
@use "../../../../../assets/stylesheets/functions/text";
@use "../../../../../assets/stylesheets/functions/shadows";
@use "../../../../../assets/stylesheets/functions/spacing";
@use "../../../../../assets/stylesheets/functions/truncate";
@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/typography/a";
@use "../../../../../assets/stylesheets/typography/fonts";
@use "../../../../../assets/stylesheets/typography/h";
@use "../../../../../assets/stylesheets/typography/list";
@use "../../../../../assets/stylesheets/utilities/grid";
@use "../../../../../assets/stylesheets/variables";

$border-bottom-color-overlay: rgba(variables.color("white"), 0.5);
$border-bottom-width: 0.25rem;
$icon-menu-bar-height: 0.1875rem;
$icon-menu-base-width: 1rem;
$menu-closed-height-sm: 0;
$menu-closed-height-md: 6rem;
$nav-section-padding: 2rem;
$nav-link-spacing: 0.75rem;
$nav-menu-height: 4rem;
$nav-primary-mb: $nav-link-spacing - $border-bottom-width;
$header-gradient: linear-gradient(to bottom, rgba(variables.color("black"), 0.9) 0%, rgba(variables.color("black"), 0) 100%);
$header-z-index: deep-map-get(variables.$z-indexes, header);

.main-nav {
  margin-bottom: 0;
  transform: translateY(0);
  transition: transform (variables.$transition-base-duration * 2) ease;
  will-change: transform;

  &.open {
    .menu-mobile-text {
      margin-right: 0.25rem;

      @include mq.mq($from: md) {
        margin-right: 0.5rem;
      }
    }

    .home-link {
      @include mq.mq($until: md) {
        flex-shrink: 0;
        overflow: hidden;
        width: 1.25rem;
      }
    }

    .account-links {
      @include mq.mq($until: md) {
        display: flex;
        flex-grow: 1;
      }
    }

    .nav {
      @include mq.mq($from: md) {
        background: linear-gradient(to bottom, rgba(variables.color("black"), 0.9) 0%, rgba(variables.color("black"), 0.75) 95%, rgba(variables.color("black"), 0) 100%);
      }
    }

    .nav,
    .nav-menu {
      height: auto;
      min-height: 25rem;

      @each $bp, $masthead-height in variables.$issuer-masthead-heights {
        @include breakpoints.bp-up($bp) {
          min-height: $masthead-height;
        }

        .home & {
          min-height: map.get(variables.$issuer-masthead-home-heights, $bp);
        }
      }
    }
  }

  &.pre-sticky {
    transform: translateY(-100%);
  }

  &.sticky {
    margin-bottom: 0;
  }

  @include mq.mq($from: md) {
    margin-bottom: 0;
  }

  .headers-primary {
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: 0;

    @include mq.mq($from:lg) {
      font-size: 1.75em;
    }

    @include mq.mq($from: xl) {
      font-size: 2em;
    }
  }

  .headers-secondary {
    @extend %h5;

    color: var(--accent-2-700);
  }
}

.issuer-bondlink-bar {
  align-items: center;
  background-color: variables.color(link, color);
  display: flex;
  flex-flow: row wrap;
  height: auto;
  justify-content: space-between;
  position: relative;

  @include mq.mq($until: md) {
    padding-left: variables.$bondlink-bar-padding-lr-xs;
    padding-right: variables.$bondlink-bar-padding-lr-xs;
  }

  @include mq.mq($from: md) {
    align-items: flex-start;
    justify-content: space-between;
    @include breakpoints.bp-prop(height, variables.$issuer-nav-bar-heights);
  }

  .sticky & {
    @extend %box-shadow-2;

    flex-wrap: nowrap;
    justify-content: flex-start;
    @include breakpoints.bp-prop(height, variables.$issuer-nav-bar-sticky-heights);
  }

  .actions {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    height: 100%;

    .main-nav & {
      @include breakpoints.bp-props(variables.$issuer-nav-bar-actions-props);
    }

    .main-nav:not(.sticky) & {
      @extend .inverted;
    }

    .main-nav.sticky & {
      @extend .inverted;

      display: none;
      @include breakpoints.bp-props(variables.$issuer-nav-bar-sticky-actions-props);

      @include mq.mq($from: lg) {
        display: flex;
        padding-bottom: $nav-primary-mb;
        width: auto;
      }
    }
  }

  .home-link {
    @include mq.mq($from: md) {
      flex: 0 0 auto;
      margin-bottom: 0.25rem;
    }

    @include mq.mq($from: lg) {
      margin-bottom: 0;
    }

    .sticky & {
      display: none;

      @include mq.mq($from: lg) {
        display: block;
        flex: 0 1 auto;
        overflow: hidden;
        width: 1.5rem;
      }
    }
  }

  .account-links {
    display: none;

    @include mq.mq($from: md) {
      display: flex;
    }
  }

  .account-link {
    $account-link-height: 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    height: $account-link-height;
    line-height: $account-link-height;

    &:not(:last-child) {
      margin-right: 1rem;

      @include mq.mq($from: lg) {
        margin-right: 1.5rem;
      }
    }

    @include mq.mq($from: md) {
      padding-bottom: 0;
    }
  }
}

.bank-bondlink-bar {
  @extend %box-shadow-2;

  align-items: center;
  background-color: variables.color("white");
  column-gap: 2rem;
  display: flex;
  flex-flow: row wrap;
  height: auto;
  justify-content: space-between;
  position: relative;

  @include breakpoints.bp-prop(height, variables.$bank-nav-bar-heights);
  @include breakpoints.bp-prop(padding-top, variables.$bank-nav-bar-padding-y);
  @include breakpoints.bp-prop(padding-bottom, variables.$bank-nav-bar-padding-y);
  @include breakpoints.bp-prop(padding-right, variables.$bank-nav-bar-padding-x);
  @include breakpoints.bp-prop(padding-left, variables.$bank-nav-bar-padding-x);

  @include mq.mq($from: md) {
    flex-wrap: nowrap;
  }

  .sticky & {
    @include breakpoints.bp-prop(height, variables.$bank-nav-bar-sticky-heights);
    @include breakpoints.bp-prop(padding-top, variables.$bank-nav-bar-sticky-padding-y);
    @include breakpoints.bp-prop(padding-bottom, variables.$bank-nav-bar-sticky-padding-y);

    .blp-logo {
      display: none;
    }

    .log-out {
      display: none;
    }
  }

  .blp-logo {
    height: 1.25rem;

    @include mq.mq($from: md) {
      margin-bottom: 1rem;
    }
  }

  .actions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;

    @include mq.mq($from: md) {
      align-items: flex-end;
      width: fit-content;
    }

    .sticky & {
      display: none;

      @include mq.mq($from: md) {
        display: flex;
      }
    }
  }

  .account-links {
    column-gap: 1rem;
    display: flex;
    flex-shrink: 0;
    @include mq.mq($from: lg) {
      column-gap: 1.5rem;
    }
  }
}

.sticky-headers {
  display: none;

  .sticky & {
    @extend .inverted;

    display: block;
    flex: 1 1 auto;
    flex-direction: column;
    padding-right: 1rem;

    @include mq.mq($from: md) {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .headers-primary {
      $base-line-height: 1.125rem;

      @extend .truncate;

      flex: 0 0 auto;

      .bank-name,
      .issuer-name {
        @include text.text-sizing(0.875rem, $base-line-height);
        @include truncate.truncate-lines(2);
      }

      .bank-name {
        $bank-line-height-xs: 1.25rem;
        $bank-line-height-md: 1.5rem;
        @extend %font-sans-normal-500;

        line-height: $bank-line-height-xs;
        @include truncate.truncate-lines(2);

        @include mq.mq($from: md) {
          font-size: 1.125rem;
          line-height: $bank-line-height-md;
          @include truncate.truncate-lines(2);
        }
      }

      .issuer-name {
        $issuer-line-height-md: 1.25rem;
        @extend %font-sans-normal-400;

        @include mq.mq($from: md) {
          font-size: 1rem;
          line-height: $issuer-line-height-md;
          @include truncate.truncate-lines(1);
        }
      }
    }

    .navigation {
      display: none;
      flex: 0 0 auto;

      @include mq.mq($from: md) {
        display: flex;
      }
    }
  }
}

.issuer-headers {
  .headers-primary {
    line-height: 2rem;
    margin-bottom: 0;
    margin-top: 0;

    @include mq.mq($from:lg) {
      font-size: 1.75em;
    }
  }

  .headers-secondary {
    @extend %h5;

    .sticky & {
      display: none;

      @include mq.mq($from: md) {
        display: flex;
        flex: auto;
      }
    }
  }

  .nav-link-primary {
    display: none;

    @include mq.mq($from: md) {
      display: block;
      margin-top: 0;
    }
  }

  .navigation {
    display: none;

    @include mq.mq($from: md) {
      display: flex;
      padding: 0 0.5rem;
    }
  }
}

.bondlink-logo {
  justify-self: start;
  overflow: hidden;

  @include mq.mq($from: lg) {
    margin-left: variables.$issuer-logo-offset-lg;
  }

  @include mq.mq($from: xl) {
    margin-left: variables.$issuer-logo-offset-xl;
  }

  img,
  svg {
    height: 1.25rem;
    margin-bottom: 0;
    max-width: none;
    vertical-align: sub;

    .open & {
      transform: translateX(-84%);

      @include mq.mq($from: md) {
        transform: none;
      }
    }
  }
}

.menu-mobile {
  flex-shrink: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 1rem 0 1rem 1rem;
  z-index: $header-z-index + 1;

  @include mq.mq($from: md) {
    position: absolute;
    right: breakpoints.bp-leq(variables.$grid-gutter-widths, md, deep-map-get(variables.$grid-gutter-widths, xs));
    top: deep-map-get(variables.$issuer-nav-bar-heights, md);
  }

  @include mq.mq($from: lg) {
    display: none;
  }

  .menu-mobile-container {
    @extend %font-sans-normal-700;

    @include mq.mq($from: md) {
      border-bottom: $border-bottom-width solid $border-bottom-color-overlay;
      font-size: 1.25rem;
      line-height: 2rem;
      position: relative;
      text-transform: uppercase;
    }
  }

  .sticky & {
    @include mq.mq($from: md) {
      display: none;
    }
  }

  .menu-mobile-text {
    margin-right: 0.5rem;
    text-transform: uppercase;
  }
}

.nav-headers {
  display: none;
  position: absolute;

  @include mq.mq($from: lg) {
    display: block;
  }

  .main-nav.open &.nav {
    background: $header-gradient;
    height: $nav-menu-height;
    min-height: 0;
  }
}

.nav-lists-container {
  overflow: hidden;
  position: absolute;
  transform: translateY(-100%);
  transition: transform variables.$transition-base-duration ease;
  width: 100%;
  z-index: deep-map-get(variables.$z-indexes, below-all);

  @include mq.mq($from: md) {
    background: $header-gradient;
    box-shadow: none;
    transform: translateY(0) !important;

    .nav-menu {
      height: auto;
    }

    .nav-lists {
      transform: translateY(-100%);
      transition: transform variables.$transition-base-duration ease;

      .open & {
        transform: translateY(0);
      }
    }
  }

  @include mq.mq($from: lg) {
    a.nav-link-primary {
      visibility: hidden;
    }
  }

  .open & {
    @extend %box-shadow-2;

    transform: translateY(0);

    @include mq.mq($from: md) {
      box-shadow: none;
    }
  }

  .sticky & {
    @include mq.mq($from: md) {
      display: none;
    }
  }
}

.nav {
  background-color: variables.color("white");
  overflow: hidden;
  width: 100%;
  z-index: $header-z-index;

  @include mq.mq($from: md) {
    background: linear-gradient(to bottom, rgba(variables.color("black"), 0.75) 0%, rgba(variables.color("black"), 0.5) 50%, rgba(variables.color("black"), 0) 100%);
    height: $menu-closed-height-md;
    margin-top: 0;
    padding-top: 7.5rem;
  }

  @include mq.mq($from: lg) {
    padding-left: deep-map-get(variables.$issuer-logo-widths, lg);
    padding-top: 0;
  }

  @include mq.mq($from: xl) {
    padding-left: deep-map-get(variables.$issuer-logo-widths, xl);
  }

  .open & {
    padding-top: 1.5rem;

    @include mq.mq($from: md) {
      padding-top: 0;
    }
  }

  .sticky & {
    margin-top: 0;

    @include mq.mq($from: md) {
      display: none;
    }
  }
}

.nav-menu {
  overflow: hidden;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  @include mq.mq($from: md) {
    height: 0;
    padding-bottom: 2rem;
    padding-top: 7rem;

    @each $bp, $width in variables.$grid-gutter-widths {
      @include breakpoints.bp-up($bp) {
        @include grid.container-spacing(padding, $width);
      }
    }

    &.related {
      padding-top: 10rem;
    }
  }

  @include mq.mq($from: lg) {
    display: flex;
    height: 4rem;
    padding-top: 0;

    &.related {
      padding-top: 0;
    }
  }

  .sticky & {
    height: auto;


    @include mq.mq($from: md) {
      display: block;
      padding: 0 0.5rem;
    }
  }

  .open.sticky & {
    display: block;
    height: auto;
  }
}

.navigation {
  display: flex;
  flex: auto;
  flex-wrap: wrap;

  @include mq.mq($until: md) {
    margin-left: -$nav-section-padding * 0.5;
    margin-right: -$nav-section-padding * 0.5;
  }

  @include mq.mq($from: md) {
    flex-wrap: nowrap;

    .about {
      width: 6.1875rem;
    }

    .bonds {
      width: 6.3125rem;
    }

    .documents {
      width: 9.6875rem;
    }

    .resources {
      width: 9.375rem;
    }

  }

  @include mq.mq($from: lg) {
    margin-left: variables.$issuer-logo-offset-lg;
    margin-top: 1rem;
  }

  @include mq.mq($from: xl) {
    margin-left: variables.$issuer-logo-offset-xl;
  }

  .sticky:not(.open) & {
    margin: 0;

    .about,
    .bonds,
    .documents,
    .resources {
      flex: 0 1 auto;
    }
  }

  .about,
  .bonds,
  .documents,
  .resources {
    flex: 1 1 calc(50% - 2rem);
    padding: 0 ($nav-section-padding * 0.5);
    word-break: normal;

    @include mq.mq($from: md) {
      flex-basis: auto;
      padding: 0 $nav-section-padding 0 0;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  ul {
    @extend .list-unstyled;

    li {
      margin-bottom: $nav-link-spacing;
    }
  }
}

.nav-link-primary {
  @extend %font-size-large;
  @extend %font-sans-normal-700;

  border-bottom-width: $border-bottom-width;
  margin-bottom: $nav-primary-mb;
  text-transform: uppercase;

  @include mq.mq($from: md) {
    @extend %anchor-state-styles-white-md;

    border-bottom-color: $border-bottom-color-overlay;

    .sticky & {
      border-bottom-color: transparent;
    }

  }
}

.nav-link-secondary {
  @extend %font-size-small;

  @include mq.mq($from: md) {
    @extend %anchor-state-styles-white-md;
    @extend %text-shadow-md-1;

    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.primary-officer {
  $line-height-md: 1.25rem;

  display: none;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.75rem;
  margin-top: 1rem;
  opacity: 0;

  @include mq.mq($from: md) {
    color: variables.color("white"); // stylelint-disable-line
    font-size: 1rem;
    line-height: $line-height-md;
    margin-left: 0;
  }

  .open & {
    display: block;
    opacity: 1;

    @include mq.mq($from: md) {
      bottom: -1rem;
      left: 0;
      position: absolute;
      transform: translateY(100%);
    }
  }

  .sticky & {
    display: none;
  }
}

.primary-officer-name {
  @extend %font-sans-normal-700;

  @include mq.mq($from: md) {
    @extend %text-shadow-md-1;
  }
}

.roadshow-nav {
  height: deep-map-get(variables.$issuer-nav-bar-heights, xs);

  @include mq.mq($from: md) {
    height: variables.$roadshow-header-md-height;
  }

  .bondlink-bar {
    flex-wrap: nowrap;
    height: 100%;
    justify-content: flex-start;
    @include spacing.spacing-x(padding, 2rem);
  }

  .issuer-logo {
    border-radius: 0;
    display: block;
    height: deep-map-get(variables.$issuer-nav-bar-heights, xs);
    margin-left: -1rem;
    margin-right: 0.5rem;
    padding: 0.25rem;
    width: 4rem;

    @include mq.mq($from: md) {
      height: 4.5rem;
      margin-left: 0;
      margin-right: 1rem;
      width: 5.5rem;
      @include shadows.box-shadow(2);
    }

    .image {
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      width: 3.5rem;

      @include mq.mq($from: md) {
        width: 5rem;
      }

      img {
        display: block;
        margin: auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .sticky-headers {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 0.5rem;

    .headers-primary {
      @extend %font-sans-normal-400;

      margin: 0.5rem 0 0;
      @include text.text-sizing(1rem, 1.25rem);

      @include mq.mq($from:md) {
        font-size: 1.5rem;
      }

    }

    a {
      @include text.text-sizing(0.75rem, 1rem);

      @include mq.mq($from: md) {
        @include text.text-sizing(1rem, 1.5rem);
      }
    }
  }

  .actions {
    align-items: flex-end;
    display: none;
    flex-flow: column nowrap;
    justify-content: flex-end;

    @include mq.mq($from: md) {
      display: flex;
    }

    > div {
      padding-bottom: 0.5rem;
    }
  }
}
