@use "../functions/shadows" as shadowFns;
@use "../typography/fonts";
@use "../utilities/shadows";
@use "../variables";

$toggle-off-opacity: 0.5;
$default-outline-color: rgba(0, 103, 244, 0.75); // hack to mimic focus outline

$dot-margin: 0.25rem;
$dot-size: variables.$toggle-switch-height - ($dot-margin * 2);
$text-padding: 0.5rem;
$text-width: 2.5rem;

.toggle-container {
  color: variables.color("gray", 700);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &.disabled {
    .toggle-switch {
      opacity: $toggle-off-opacity;
      pointer-events: none;
      button {
        cursor: default;
      }
    }

    .font-sans-normal-500,
    .font-sans-normal-400,
    .toggle-text {
      color: variables.color("gray", 600);
    }
  }

  .toggle-note {
    color: variables.color("gray", 600);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: variables.$toggle-switch-width + 1rem;
    margin-top: 0.25rem;
  }

  .toggle-note-on {
    color: #000;
  }

  .toggle-row {
    @extend %font-sans-normal-500;
    align-items: center;
    display: flex;

    .toggle-switch {
      margin-right: 0.5rem;
    }

    .toggle-text {
      flex-shrink: 2;
    }

    .toggle-text-on {
      color: variables.color("black");

      &.muted {
        color: variables.color("gray", 700) !important;
      }
    }
  }
}

.toggle-switch {
  display: flex;
  position: relative;
  user-select: none;

  .icon {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    flex: 0 0 auto;
    height: variables.$toggle-switch-height;
    margin-right: 0.5rem;
    opacity: $toggle-off-opacity;
    transition: opacity variables.$toggle-switch-transition;
    width: variables.$toggle-switch-height;
  }
}

.toggle-switch-checkbox {
  display: none;
}

.toggle-switch-label {
  display: block;
  flex: 0 0 variables.$toggle-switch-width;
  margin: 0;
  width: variables.$toggle-switch-width;

  button {
    background-color: variables.color("white");
    border: none;
    border-radius: 2rem;
    cursor: pointer;
    display: block;
    height: 100%;
    opacity: $toggle-off-opacity;
    overflow: hidden;
    padding: 0;
    transition: opacity variables.$toggle-switch-transition;
    width: $dot-size + (2 * $dot-margin) + $text-width;

    &:focus {
      @extend %focus-shadow;
    }

  }
}

.toggle-switch-inner {
  display: flex;
  transform: translateX(-$text-width);
  transition: transform variables.$toggle-switch-transition;
  width: 200%;
}

.toggle-switch-text {
  @extend %font-sans-normal-700;

  flex: 0 0 $text-width;
  font-size: 1rem;
  height: variables.$toggle-switch-height;
  line-height: variables.$toggle-switch-height;
  max-width: $text-width; // IE11 bug fix since padding plus being a flex child forgets that box-sizing:border-box exists
  text-transform: uppercase;

  &:first-of-type {
    padding-left: $text-padding;
    text-align: left;
  }

  &:last-of-type {
    padding-right: $text-padding;
    text-align: right;
  }

  &.disabled {
    text-align: center !important;
  }
}

.toggle-switch-dot {
  background: var(--accent-1-700);
  border-radius: $dot-size;
  flex: 0 0 $dot-size;
  height: $dot-size;
  margin: $dot-margin;
  width: $dot-size;
}

.toggle-switch-checkbox:checked {
  ~ .toggle-switch-label button {
    opacity: 1;

    .toggle-switch-inner {
      transform: translateX(0);
    }
  }
}

.toggle-switch.check-mark {
  .toggle-switch-dot {
    &::after {
      content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2014%2010%22%20class%3D%22icon%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M0%200h14v14H0z%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22translate%280%20-2%29%22%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cg%20fill%3D%22%23FFF%22%20mask%3D%22url%28%23b%29%22%3E%3Cpath%20d%3D%22M1.942%205.433l4.95%204.95-1.65%201.65-4.95-4.95z%22%2F%3E%3Cpath%20d%3D%22M11.841%202.133l1.65%201.65-8.25%208.25-1.65-1.65z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
      display: block;
      height: $dot-size;
      line-height: $dot-size - $dot-margin;
      opacity: 0;
      padding: $dot-margin;
      position: absolute;
      text-align: center;
      transition: opacity variables.$toggle-switch-transition;
      width: $dot-size;
    }
  }

  .toggle-switch-checkbox:checked {
    ~ .toggle-switch-label button {
      .toggle-switch-dot {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.toggle-switch.issuer-portal {
  .toggle-switch-checkbox:checked {
    ~ .toggle-switch-label button {
      background-color: variables.color(success, color);
    }
  }

  .toggle-switch-label button {
    background-color: variables.color("gray", 400);
    @include shadowFns.box-shadow(1);
    &:focus {
      @extend %focus-shadow;
    }
  }

  .toggle-switch-text {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  }

  .toggle-switch-dot {
    background: variables.color("white");
  }
}

.toggle-form {
  .alert-danger {
    margin-bottom: 1rem;
  }
}
