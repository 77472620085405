@use "../functions/overlay";
@use "../functions/text";
@use "../variables";
@use "../mq";
@use "sass:math";

$spinner-margin-top: 25vh;
$spinner-max-size: 9rem;
$spinner-min-size: 4rem;

.spinner-overlay {
  @include overlay.overlay(0, 0.7, page-loader);
}

.icon-bondlink-loader-container {
  margin: $spinner-margin-top auto 0;
  text-align: center;
  width: $spinner-max-size;
  z-index: deep-map-get(variables.$z-indexes, page-loader) + 1;

  @include overlay.overlay(0, 1, page-loader, unset);

  .icon-bondlink-loader {
    height: 15vw;
    max-height: $spinner-max-size;
    max-width: $spinner-max-size;
    min-height: $spinner-min-size;
    min-width: $spinner-min-size;
    width: 15vw;
  }

  .bondlink-loading-text {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    margin-top: 1rem;
    z-index: deep-map-get(variables.$z-indexes, page-loader) + 1;
    @include text.text-sizing(1.25rem, 1);

    @include mq.mq($from: md) {
      margin-top: 2rem;
    }
  }
}

.portal-loader-bl-icon-container {
  $scroll-window-width: 20rem;
  $scroll-window-width-md: 30rem;

  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 15vh;
  z-index: deep-map-get(variables.$z-indexes, page-loader);

  .icon-bondlink-loader {
    height: $spinner-min-size;
    width: $spinner-min-size;

    @include mq.mq($from: md) {
      height: $spinner-max-size;
      width: $spinner-max-size;
    }
  }

  .scroll-window {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    margin-top: 1rem;
    text-align: center;
    width: $scroll-window-width;
    z-index: deep-map-get(variables.$z-indexes, page-loader) + 1;

    @include text.text-sizing(1rem, 1.5);

    @include mq.mq($from: md) {
      margin-top: 2rem;
      width: $scroll-window-width-md;

      @include text.text-sizing(1.25rem, 1.5);
    }

    .messages {
      display: flex;
      gap: $scroll-window-width;
      overflow: hidden;
      width: $scroll-window-width;

      @include mq.mq($from: md) {
        gap: $scroll-window-width-md;
        width: $scroll-window-width-md;
      }

      .loading-message {
        // https://easings.net/#easeInOutQuint
        animation: 12.5s infinite cubic-bezier(0.83, 0, 0.17, 1) forwards scroll;
        display: inline-block;
        flex: 0 0 $scroll-window-width;

        @include mq.mq($from: md) {
          flex: 0 0 $scroll-window-width-md;
        }
      }
    }

    @keyframes scroll {
      $num-iterations: 4;
      $start: 25%;
      $transition-interval: 5%;
      $interval: math.div(100% - $start, $num-iterations) - math.div($transition-interval, $num-iterations);
      $end: $start + ($interval * $num-iterations);
      $interval-scroll-width: -200%;

      0% {
        opacity: 1;
      }
      0.1% {
        transform: translateX(0%);
      }

      @for $i from 0 through $num-iterations {
        $offset: $i * $interval;
        #{$start + $offset - $transition-interval} {
          opacity: 1;
          transform: translateX(#{$i * $interval-scroll-width});
        }
        #{$start + $offset} {
          opacity: 1;
          transform: translateX(#{($i + 1) * $interval-scroll-width});
        }
      }
      #{$end + 0.1} {
        opacity: 0;
        transform: translateX(#{$interval-scroll-width + ($num-iterations + 1)});
      }
      #{$end + 0.2} {
        opacity: 0;
        transform: translateX(#{$interval-scroll-width * -0.5});
      }
    }
  }
}
