@use "../../mq";
@use "../../components/card";
@use "../../functions/text";
@use "../../typography/h";
@use "../../utilities/borders";
@use "../../utilities/truncate";
@use "../../variables";

.issuer-card-grid {
  > * {
    display: flex;
    justify-content: center;

    @include mq.mq($from: md) {
      justify-content: space-evenly;
    }
  }
}

.card-issuer-overview {
  border: card.$card-border-width solid card.$card-border-color;
  border-radius: variables.$border-radius-1;
  flex: 1 1 auto;
  height: 100%;
  max-width: 26rem;
  min-width: 22rem;
  padding: 0;
  position: relative;

  &.issuer-site-overview {
    width: 100%;

    @include mq.mq($from: sm) {
      width: variables.$card-issuer-overview-sm-w;
    }
  }

  @include mq.mq($from: sm) {
    width: variables.$card-issuer-overview-sm-w;
  }

  .issuer-overview-header {
    background: variables.color("blue", 700);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: calc(variables.$border-radius-1 - card.$card-border-width) calc(variables.$border-radius-1 - card.$card-border-width) 0 0;
    cursor: pointer;
    height: 9.25rem;
    overflow: hidden;
    position: relative;

    .hioc {
      @extend %font-sans-normal-700;
      @extend %text-shadow-2;

      bottom: 0;
      left: 0;
      margin-left: variables.$card-issuer-overview-padding;
      margin-right: variables.$card-issuer-overview-padding;
      position: absolute;
      z-index: deep-map-get(variables.$z-indexes, base);
      @include text.text-sizing(1.25rem, 1.5rem);
    }
  }

  .section {
    border-bottom: borders.$border-200;
    margin: 0;

    + .section {
      margin-top: variables.$card-issuer-overview-padding;
    }
  }

  .overview-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: variables.$card-issuer-overview-padding 1rem 0.625rem;

    .no-ratings {
      flex-grow: 1;
    }

    .bond-ratings {
      .data-horizontal {
        margin-bottom: 1rem;
      }

      .data-point {
        flex: 0 1 auto;
      }
    }

    .links > div {
      margin: (card.$card-padding * 0.5) 0;
    }

    .links:last-child > div {
      margin-bottom: 0;
    }
  }

  .overview-body.issuer-site {
    padding: 0.25rem 1rem 0.625rem;
  }

  .issuer-details,
  .bond-ratings {
    .data-horizontal {
      flex-wrap: nowrap;
      margin-bottom: variables.$card-issuer-overview-padding;
    }
  }

  .issuer-details {
    display: flex;

    dd {
      @extend .truncate-1;
      white-space: normal;
    }
  }

  .bonds-header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    position: relative;

    .p8n-control-simple {
      margin: 0;
      @include text.text-sizing(0.875rem, 1.25rem);

      .btn-arrow {
        padding: 0;
      }

      .pages {
        margin: 0 0.25rem;
      }
    }
  }

  #ratings-container {
    > div {
      margin-bottom: variables.$card-issuer-overview-padding;
    }

    dl {
      margin-bottom: 0;
    }
  }

  .bond-name {
    @extend %font-sans-normal-500;
    @extend .truncate-1;
    margin-bottom: 0.5rem;
  }

  .data-point-large {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-end;

    dt {
      word-break: normal;
    }
  }

  .links {
    border-bottom: borders.$border-200;
    display: flex;

    &:last-child {
      border-bottom: none;
    }

    > div {
      @extend %h4;

      flex: 0 0 50%;
      margin: (card.$card-padding * 0.5) 0;
      padding: (card.$card-padding * 0.5) 0;

      &:first-child {
        border-right: borders.$border-200;
        padding-right: card.$card-padding * 0.5;
      }

      &:last-child {
        padding-left: 0.625rem;

        @include mq.mq($from: sm) {
          padding-left: card.$card-padding;
        }
      }
    }
  }

  .no-ratings {
    color: variables.color("gray", 600);
  }
}

