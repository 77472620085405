@use "../../../../../assets/stylesheets/components/markdown-table";
@use "../../../../../assets/stylesheets/components/sites-table";
@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/variables";

.table {
  @extend %sites-table-styles;
}

.table-sort-dropdown {
  margin-bottom: 1rem;

  @include mq.mq($from: md) {
    display: none;
  }

  .custom-select::before {
    color: variables.color("gray", 600);
    content: "Sort by: ";
  }
}

.dataTable-top,
.dataTable-info {
  display: none;
}
