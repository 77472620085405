@use "../../../../../assets/stylesheets/mq";

#anchor-news-press-releases {
  .card {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.social-icons {
  display: flex;
  flex-direction: column;
  height: 5.5rem;
  justify-content: space-between;
  margin-right: 1rem;

  @include mq.mq($from: md) {
    flex-direction: row;
    height: 2.75rem;
    justify-content: space-between;
    width: 14rem;
  }

  .social-icons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 5.5rem;
    @include mq.mq($from: md) {
      width: 6.5rem;
    }
  }

  .btn-social {
    margin-right: 0;
  }

  .tooltip-content {
    width: 13.5rem;
    @include mq.mq($from: md) {
      width: 18rem;
    }
  }
}
