@use "sass:map";
@use "../mq";
@use "../variables";
@use "../functions/breakpoints";
@use "../functions/spacing";
@use "../typography/h";
@use "../utilities/grid";

@mixin right-floated {
  @each $bp in map.keys(variables.$breakpoints) {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class("float", $bp, "right") {
        @content;
      }
    }
  }
}

section {
  $spacing-bottom: 3rem;

  margin-bottom: $spacing-bottom;
  padding-top: 0.5rem;

  @include spacing.margin-y-0-first-last-child(first, top);
  @include spacing.margin-y-0-first-last-child(last, bottom);

  &.subtle {
    background-color: var(--accent-2-100);
    margin-bottom: 0;
    padding-bottom: 3rem;
    padding-top: 2rem;

    + section {
      padding-top: 1.5rem;
    }
  }

  &.no-header {
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    h2.title {
      display: none;
    }
  }

  &.floated-content {
    @extend .clear-fix;

    @include right-floated {
      margin-bottom: 1.25rem;
      padding-left: 1.25rem;
    }

    &.no-header {
      &[data-title]::before,
      .title {
        display: none;
      }
    }

    &[data-title] {
      &::before {
        @extend %h2;

        content: attr(data-title);
        display: block;

        @include mq.mq($from: md) {
          display: none;
        }
      }
    }

    @include mq.mq($from: lg) {
      // stylelint-disable-line order/order
      $float-m: 2rem;

      @include right-floated {
        margin-bottom: $float-m;
        padding-left: $float-m;
      }
    }

    .title {
      display: none;

      @include mq.mq($from: md) {
        display: block;
      }
    }

    .data-points .data-point-medium:first-child {
      margin-top: 0;
    }
  }

  @include mq.mq($from: md) {
    padding-top: 1rem;
  }

  #bond-ratings &:last-child {
    margin-bottom: $spacing-bottom;
  }
}
