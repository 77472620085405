@use "../mq";

$bl-img-margin: 1rem;

img {
  margin-bottom: $bl-img-margin * 0.5;
  max-width: 100%;

  @include mq.mq($from: md) {
    margin-bottom: 0;
  }
}

[bl-image="small"] {
  float: left;
  height: auto;
  margin-right: $bl-img-margin;
  width: 40%;


  @include mq.mq($from: sm) {
    width: 35%;
  }
}

[bl-image="small-right"] {
  float: right;
  height: auto;
  margin-left: $bl-img-margin * 0.5;
  width: 40%;

  @include mq.mq($from: sm) {
    width: 35%;
  }
}

[bl-image="half"] {
  float: left;
  height: auto;
  margin-right: $bl-img-margin;
  width: 100%;

  @include mq.mq($from: md) {
    width: 50%;
  }
}

[bl-image="half-right"] {
  float: right;
  height: auto;
  margin-left: $bl-img-margin * 0.5;
  width: 100%;

  @include mq.mq($from: md) {
    width: 50%;
  }
}

[bl-image="full"] {
  height: auto;
  width: 100%;
}
