@use "../variables";
@use "../mq";
@use "../typography/a";
@use "../typography/fonts";
@use "../typography/list";
@use "../utilities/borders";

.p8n-control-simple {
  align-items: center;
  display: flex;
  margin: 0 (-(variables.$btn-arrow-padding) - 0.25rem);

  .pages {
    @extend .small;
    position: relative;
    top: -1px;
  }
}

.dataTable-pagination {
  $block-border-width: 0.125rem;
  $block-space-between: 0.5rem;
  $block-size: 2rem;

  @extend .list-unstyled;

  display: flex;
  font-size: 1rem;
  justify-content: center;
  line-height: 1rem;
  margin: 2rem auto;

  &:empty {
    display: none;
  }

  .dataTable-pagination-list {
    display: flex;
  }

  li {
    margin-bottom: 0;
  }

  li a {
    @extend %border-radius-1;

    align-items: center;
    display: flex !important;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  li:not(.pager):not(.ellipsis) a {
    padding: 0 0.5rem;
  }

  li:not(.ellipsis) {
    cursor: pointer;
    height: $block-size;
    margin-left: $block-space-between * 0.5;
    margin-right: $block-space-between * 0.5;
    min-width: $block-size;

    &.active {
      @extend %font-sans-normal-700;

      &:hover,
      &:active {
        a {
          background-color: variables.color("white");
        }
      }

      a {
        background-color: variables.color("white");
        border-color: variables.color("black");
        color: variables.color("black");
      }
    }

    &.pager {
      display: none;
      margin-left: $block-space-between;
      margin-right: $block-space-between;
      width: 3rem;

      @include mq.mq($from: sm) {
        display: block;
      }
    }

    &.disabled {
      pointer-events: none;

      a {
        color: variables.color("gray", 300);
      }
    }

    &[disabled] {
      cursor: auto;
    }

    a {
      background-color: variables.color("white");  // stylelint-disable-line declaration-property-value-blacklist
      border: $block-border-width solid variables.color("gray", 300);
      color: variables.color(link, color);
    }
  }

  .ellipsis {
    margin: $block-space-between * 0.5;

    a {
      @extend .no-decoration;

      color: variables.color(link, disabled, color);

      &:hover {
        cursor: default;
      }
    }
  }
}
