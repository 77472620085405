@use "../mq";
@use "../typography/fonts";
@use "../variables";

.bond-rating {
  h5 {
    margin-bottom: 0.5rem;
  }

  .rating-score {
    @extend %font-sans-normal-700;

    align-items: center;
    background-color: var(--accent-2-700);
    border-radius: 0.125rem;
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    display: flex;
    font-size: 2.25rem;
    height: 6.25rem;
    justify-content: center;
    line-height: 2.25rem;
    margin-right: 1rem;
    text-align: center;
    width: 7rem;

    @include mq.mq($from: md) {
      font-size: 2.5rem;
      height: 7rem;
      line-height: 2.5rem;
      width: 7.5rem;
    }
  }

  .data-horizontal {
    margin-bottom: 0.5rem;

    .data-point {
      margin-top: 0;
    }
  }

  .modal-header {

    .modal-title {
      h2 {
        margin-right: 2rem;
      }
    }
  }
}
