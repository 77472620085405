@use "../variables";
@use "markdown-table";
@use "../mq";
@use "../typography/a";
@use "../typography/fonts";
@use "../utilities/borders";

%sites-table-styles {
  @extend .bt-0125, .bt-md-0;

  @include markdown-table.markdown-table-styles(md);

  &.rte-table tr :last-child {
    text-align: left;
  }

  th {
    &.asc a,
    &.desc a,
    .table-header-button.asc,
    .table-header-button.desc {
      @extend %font-sans-normal-700;
      color: variables.color("black");
    }

    &.asc a::after,
    .table-header-button.asc::after {
      content: "↑";
    }

    &.desc a::after,
    .table-header-button.desc::after {
      content: "↓";
    }

    a {
      @extend .no-decoration;
      color: var(--accent-1-700);
    }
  }

  .table-header-button {
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  tr {
    @include mq.mq($from: md) {
      > :last-child:not(:first-child) {
        text-align: right;
      }
    }
  }

  td {
    display: flex;
    vertical-align: top;

    &:empty {
      display: none;

      @include mq.mq($from: md) {
        display: table-cell;
      }
    }

    &::before {
      @extend %h6;

      content: attr(data-title);
      flex: 0 0 6rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      margin-bottom: 0.25rem;
      margin-right: 1rem;
      text-transform: uppercase;

      @include mq.mq($from: md) {
        display: none;
      }
    }

    &.dataTables-empty::before {
      display: none;
    }

    @include mq.mq($from: md) {
      display: table-cell;
    }

    [class^="pagelink"],
    [class*=" pagelink"] {
      margin-top: 0;
      white-space: nowrap;
    }
  }
}
