@use "sass:math";
@use "../components/sites-table";
@use "../functions/overflow";
@use "../functions/truncate";
@use "../typography/a";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../utilities/flex";
@use "../variables";
@use "../mq";
@use "markdown-table";

$skeleton-color: #eee;
$table-border: borders.$border-300;
$table-border-radius: 0.25rem;
$row-padding: 0.5rem;
$row-start-end-padding: 0.875rem;
$cell-image-width: 5rem;
$cell-image-height: 5.5rem;
$cell-svg-size: 3.5rem;

%override-dx-min-width-inline-style {
  min-width: auto !important;
  width: 100%;
}

@mixin colgroupStyles($bp) {
  colgroup {
    display: none;
    @include mq.mq($from: $bp) {
      display: table-column-group;
    }
  }
}

@mixin tableSimpleStyles($bp) {
  background-color: transparent;
  border: none;
  box-shadow: none;
  margin: 0.5rem 0;
  width: 100%;

  thead,
  tbody,
  th,
  td,
  tr {
    background-color: transparent;
  }

  tr.rejected {
    background-color: variables.color(danger, bg);
  }

  thead {
    tr {
      th {
        @extend %h6;
        border-bottom: borders.$border-200;
        border-top: none;
        padding: 0.25rem 1rem 0.25rem 0;
      }
    }
  }

  tbody {
    tr {
      border-bottom: none;
      border-top: borders.$border-200;
      padding: 0.5rem 0 0.25rem;

      &:last-child {
        border-bottom: borders.$border-200;
      }

      &:hover {
        background-color: variables.color("white");
      }

      td {
        border-bottom: none;

        padding: 0.125rem 0;
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          display: inline !important;
        }

        @include mq.mq($from: $bp) {
          border-bottom: borders.$border-200;
          padding: 0.25rem 1rem 0.25rem 0;

          &::before {
            display: none !important;
          }
        }
      }
    }
  }
}

@mixin tableLoaderCol($bp) {
  .table-loader-inner {
    border: $table-border;
    border-bottom-left-radius: $table-border-radius;
    border-bottom-right-radius: $table-border-radius;
    border-color: $skeleton-color;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    flex-direction: column;

    @include mq.mq($from: $bp) {
      flex-direction: row;
    }
  }
  .table-loader-col {
    display: none;
    flex-direction: column;
    margin-top: 0;
    padding: 0 2rem;
    width: 100%;
    &:first-of-type {
      display: flex;
      margin-top: 2rem;
    }

    @include mq.mq($from: $bp) {
      display: flex;
      margin-top: 2rem;
      padding: 0 1rem;
      width: 34%;
      &:first-of-type {
        padding-left: 2rem;
      }
      &:last-of-type {
        padding-right: 2rem;
      }
    }
  }
}

@mixin tableActionRight($bp) {
  .table-action-right {
    .table-actions {
      .left-table-actions {
        @include mq.mq($from: $bp) {
          justify-content: space-between;
        }
        .table-action {
          @include mq.mq($from: $bp) {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

@mixin portalTableContainerTable($bp) {
  table {
    @extend %box-shadow-1;
    @extend %override-dx-min-width-inline-style;
    background-color: variables.color("white");
    border: $table-border;
    border-bottom: none;
    border-collapse: unset;
    border-spacing: 0;
    table-layout: auto;
    @include colgroupStyles($bp);

    @include mq.mq($from: $bp) {
      border-radius: $table-border-radius;
    }

    thead {
      @extend %h4;
      display: none;

      @include mq.mq($from: $bp) {
        background-color: variables.color("gray", 300);
        display: table-header-group;
        margin: 1rem;

        th:first-child {
          padding-left: $row-start-end-padding;
        }

        th:last-child {
          padding-right: $row-start-end-padding;
        }
      }
      td,
      th {
        padding: 0.5rem;
      }

      th {
        .header-tooltip {
          svg {
            color: variables.color(link, color);
          }
        }
      }
    }

    tbody {
      color: variables.color("gray", 800);
      tr:hover {
        background-color: variables.color(link, bg);
      }
      th,
      td,
      tr {
        padding: 0.5rem;
      }
      tr {
        background-color: variables.color("white");
      }
    }

    tr,
    td,
    th {
      display: block;
      padding: 0;
    }

    tr {
      @extend .portal-table-row;
    }

    table.table-simple {
      @include tableSimpleStyles(md);
    }

    th {
      @extend %h4;

      display: none;
      text-align: left;
      vertical-align: bottom;
      white-space: nowrap;
      &.asc a,
      &.desc a {
        @extend %font-sans-normal-700;
        color: variables.color("black");
      }

      &.asc a::after {
        content: "↑";
      }

      &.desc a::after {
        content: "↓";
      }

      @include mq.mq($from: $bp) {
        display: table-cell;
      }

      a {
        @extend .no-decoration;
        color: variables.color("blue", 700);
      }
    }
  }
}

@mixin tableActions($bp) {
  .table-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;

    &.separator {
      border-bottom: borders.$border-300;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }

    @include mq.mq($from: $bp) {
      flex-direction: row;
      margin-bottom: 0;
    }

    .btn {
      margin: 0;
      margin-bottom: auto;
    }

    .left-action-prepend {
      &:empty {
        display: none;
      }
      @include mq.mq($from: $bp) {
        flex: 1 0 auto;
        margin-right: 1rem;
      }
    }

    .left-table-actions,
    .center-table-actions {
      margin-right: 1rem;
    }

    .left-table-actions,
    .center-table-actions,
    .right-table-actions {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include mq.mq($from: $bp) {
        flex-direction: row;
        flex-grow: 1;
        justify-content: flex-start;
        margin-bottom: 0;
      }

      .sort {
        @include mq.mq($from: $bp) {
          flex: 1 0 19rem;
        }
      }

      .table-action {
        @include mq.mq($from: $bp) {
          display: flex;
          flex: 1 0 15rem;
          .maturity-select {
            width: 100%;
          }
        }
      }
    }

    .right-table-actions {
      &:empty {
        all: unset;
      }
      @include mq.mq($from: $bp) {
        justify-content: flex-end;
      }
    }

    .sort,
    .table-action,
    .search {
      max-width: 100%;
    }

    .sort {
      .bl-react-select-container {
        .bl-react-select__single-value {
          &::before {
            color: variables.color("gray", 600);
            content: "Sort By: ";
          }
        }
      }
    }

    .search {
      flex: 0 0 auto;
      width: auto;
      @include mq.mq($from: $bp) {
        flex: 1 0 19rem;
      }
    }

    .search-input {
      &::placeholder {
        font-style: italic; // stylelint-disable-line property-disallowed-list
      }
      &:-ms-input-placeholder {
        font-style: italic; // stylelint-disable-line property-disallowed-list
      }
    }
  }
}

@mixin tableCell($bp) {
  .portal-table-cell {
    margin: 0;

    @at-root body > .portal-table-row & {
      display: none;
    }

    &.cell-control {
      margin: 0;
      min-width: 2.5rem;
      padding: 0;

      @at-root body > .portal-table-row & {
        border-bottom: none;
        display: table-cell;
      }

      &::before {
        display: none;
      }

      @include mq.mq($from: $bp) {
        padding: 0.5rem;
      }

      .control-icon {
        position: absolute;
        right: 0.5rem;
        top: 0.25rem;

        &.control-expand {
          cursor: pointer;
          svg {
            color: variables.color(link, color);
          }
        }
        &.disabled {
          svg {
            color: variables.color("gray", 300);
          }
        }
        @include mq.mq($from: $bp) {
          position: static;
        }
      }

      + td {
        padding-right: 2.5rem;

        @include mq.mq($from: $bp) {
          padding-right: 0.5rem;
        }
      }

      .control-drag-handle {
        cursor: ns-resize;
        display: none;

        @include mq.mq($from: $bp) {
          display: inline;
        }
        svg {
          color: variables.color(link, color);
        }
      }
      .icon-edit-row:hover {
        text-decoration: none;
      }
    }

    &.cell-primary {
      @extend %font-sans-normal-500;
      color: variables.color("black");

      @at-root body > .portal-table-row & {
        border-bottom: none;
        display: table-cell;
      }

      @include mq.mq($from: $bp) {
        min-width: 10rem;
        &.disable-min-width {
          min-width: 0;
        }
      }

      .truncated {
        @extend .truncate;
        @include truncate.truncate-lines(1);
      }

      .badge-cell-text {
        margin-right: 0.5rem;
        @include mq.mq($from: $bp) {
          margin-right: 0.25rem;
        }
      }

      span.badge {
        border: none;
        min-width: 2.75rem;
      }
    }

    &.icon-text-cell {
      color: variables.color("gray", 300);
      p {
        display: inline;
      }
      .active {
        color: variables.color("black");
      }
      .icon {
        margin-right: 0.125rem;
      }
    }

    &.cell-photo {
      @include mq.mq($from: $bp) {
        margin: auto;
        text-align: center;
        vertical-align: middle;
      }
      img {
        max-height: 4.5rem;
        max-width: 6rem;
      }
      p {
        margin: 0;
        overflow: auto; // stylelint-disable-line declaration-property-value-disallowed-list
        text-align: left;
      }
    }

    &.cell-image-with-count {
      .image-container {
        height: 4.5rem;
        width: 6rem;
        .image-count-overlay {
          height: 2.75rem;
        }
      }
    }

    &.cell-sector-state {
      @include mq.mq($from: $bp) {
        min-width: 8rem;
      }
    }

    .draft-tooltip-icon {
      svg {
        fill: variables.color(warning, alt-color);
      }
    }

    span.series-icons {
      display: flex;
      flex-direction: row;
      svg.icon.icon-multiple-items {
        margin-left: 0.25rem;
      }
    }

    span.icons {
      svg {
        height: 1.5rem;
        margin-left: 0.5rem;
        max-width: 1.5rem;
      }
      .hidden {
        .icon {
          fill: variables.color("gray", 600);
        }
      }
      .draft {
        .icon {
          fill: variables.color(warning, alt-color);
        }
      }
      .blue {
        .icon {
          fill: variables.color(link, color);
        }
      }
    }

    .cell-image {
      background-color: variables.color("gray", 300);
      height: $cell-image-height;
      width: $cell-image-width;
      img {
        height: $cell-image-height;
        object-fit: cover;
        object-position: 50% 0%;
        width: 100%;
      }
      svg {
        color: variables.color("gray", 400);
        height: $cell-svg-size;
        margin: (($cell-image-height - $cell-svg-size) * 0.5) (($cell-image-width - $cell-svg-size) * 0.5);
        width: $cell-svg-size;
      }
    }

    .icon-content {
      color: variables.color("black");
    }
    .icon-empty {
      color: variables.color("gray", 400);
    }

    .inactive {
      color: variables.color("gray", 300);
    }
    .action-label {
      margin-left: 0.25rem;

      @include mq.mq($from: $bp) {
        display: none;
      }
    }
  }
}

@mixin portalTableRow($bp) {
  .portal-table-row {
    border-bottom: $table-border;
    position: relative;

    &.row-inactive {
      background-color: variables.color("gray", 100);
    }
    &.disabled {
      background-color: variables.color("gray", 100);
      color: variables.color("gray", 600);
      &:hover {
        background-color: variables.color("gray", 100);
      }
      &.on-req {
        pointer-events: none;
      }
      .cell-primary {
        color: variables.color("gray", 600) !important;
      }
      a.count {
        color: variables.color("gray", 600);
        cursor: default;
        pointer-events: none;
      }
      .portal-table-cell {
        color: variables.color("gray", 600);
        fill: variables.color("gray", 300);
        button.icon.disabled {
          color: variables.color("gray", 300);
          fill: variables.color("gray", 300);
        }
      }
    }
    &.matured {
      background-color: variables.color("gray", 200);
    }
    &.muted {
      background-color: variables.color("gray", 100);
    }
    &.draft {
      background-color: variables.color(warning, bg);
    }
    &.rejected {
      background-color: variables.color(danger, bg);
    }
    &.pending {
      background-color: variables.color("magenta", 100);
    }
    &.row-expanded {
      background-color: variables.color("blue", 100);
      border-color: variables.color(link, color);
      border-style: solid;

      border-width: 0.125rem 0.125rem 0 0.125rem;
      margin: -0.125rem -0.125rem 0;
      &.draft {
        background-color: variables.color(warning, bg);
      }
      &.row-error {
        td {
          &:after {
            background-color: variables.color(danger, color);
          }
        }
      }

      @include mq.mq($from: $bp) {
        margin: 0;
      }
      td {
        border-bottom: none;
        @include mq.mq($from: $bp) {
          position: relative;

          &:after {
            background-color: variables.color(link, color);
            content: "";
            display: block;
            height: 0.125rem;
            left: 0;
            position: absolute;
            top: -0.125rem;
            width: 100%;
          }
        }
      }
    }
    &.row-error {
      border-color: variables.color(danger, color);
    }

    &.row-expanded-content {
      background-color: variables.color("blue", 100);
      border: 0.125rem solid variables.color(link, color);
      border-top: none;
      margin: 0 -0.125rem;
      padding: 0;

      &.disabled {
        color: variables.color("gray", 600);
        pointer-events: none;
        .content h4 {
          color: variables.color("gray", 600);
        }
      }

      &.draft {
        background-color: variables.color(warning, bg);
      }
      &.row-error {
        border-color: variables.color(danger, color);
        padding: 0.5rem;
        td {
          border-bottom-color: variables.color(danger, color);
          padding-bottom: 0;
        }
      }

      td.error-detail-cell {
        p.error-message {
          color: variables.color(danger, color);
          margin-bottom: 0;
          @include mq.mq($from: md){
            margin-bottom: 0.5rem;
          }
        }
      }

      td.detail-cell {
        padding: 0 1.5rem 0 0.5rem;
      }

      td {
        border-bottom-color: variables.color(link, color);

        &::before {
          display: none;
        }
        @include mq.mq($from: $bp) {
          padding: 0 0.875rem 0.5rem 0.5rem;
        }
        .content {
          $content-border: borders.$border-200;
          border-top: $content-border;
          margin-top: 1rem;
          padding: 0.5rem 0;
          width: 100%;
          &:first-child {
            margin-top: 0;
          }
          h4 {
            color: variables.color("teal", 700);
          }
          .separator {
            padding: 0.5rem 0;
          }
        }
        p {
          margin-bottom: 0.5rem;
        }
      }
    }
    &.row-expanded,
    &.row-expanded-content {
      &.row-error {
        > td {
          @include mq.mq($from: $bp) {
            &:first-child:before,
            &:last-child:before {
              background-color: variables.color(danger, color);
            }
          }
        }
      }
      > td {
        @include mq.mq($from: $bp) {
          position: relative;

          &:first-child:before,
          &:last-child:before {
            background-color: variables.color(link, color);
            content: "";
            display: block;
            height: calc(100% + 0.25rem);
            position: absolute;
            width: 0.125rem;
          }

          &:first-child:before {
            left: -0.125rem;
            top: -0.2rem;
          }
          &:last-child:before {
            right: -1.125rem;
            top: -0.2rem;
          }
        }
      }
    }

    &.row-input {
      @extend .row-inactive;

      &:hover {
        img {
          opacity: 1;
        }
      }

      &:focus {
        @extend %focus-shadow;
      }

      &:last-child {
        .cell-control {
          height: 100%;
        }
      }

      .cell-control {
        height: calc(100% - 0.125rem);
        left: 0;
        min-width: 2.5rem;
        padding: 0.5rem;
        position: absolute;
        top: 0;
        width: 2.5rem;

        @include mq.mq($from: $bp) {
          position: static;
        }

        .custom-indicator {
          position: absolute;
        }

        ~ td {
          padding-left: 3rem;
          @include mq.mq($from: $bp) {
            padding-left: 0.5rem;
          }
        }
      }

      img {
        opacity: 0.8;
      }
    }

    &.row-selected {
      background-color: variables.color("white");
      color: variables.color("gray", 700);
      img {
        opacity: 1;
      }

      .caption-text {
        color: variables.color("gray", 700);
      }

      .cell-primary {
        color: variables.color("black");
      }

      .cell-control {
        background-color: variables.color("blue", 100);
      }

      .cell-actions {
        .label {
          color: variables.color("black");
        }
      }
    }
    @include mq.mq($from: $bp) {
      display: table-row;
      padding-top: 0;

      td:first-child {
        padding-left: $row-start-end-padding;
      }

      td:last-child {
        padding-right: $row-start-end-padding;
      }
    }

    td {
      display: flex;
      margin-bottom: 0.25rem;
      vertical-align: top;

      &:empty {
        display: none;

        @include mq.mq($from: $bp) {
          display: table-cell;
        }
      }

      &::before {
        @extend %font-sans-normal-400;
        color: variables.color("gray", 600);
        content: attr(data-title);
        flex: 0 0 6rem;
        font-size: 0.75rem;
        margin-right: 1rem;
        margin-top: 0.0625rem;
        text-transform: uppercase;

        @include mq.mq($from: $bp) {
          display: none;
        }
      }

      &.row-safe-nowrap {
        @include mq.mq($from: $bp) {
          white-space: nowrap;
        }
      }

      &.dataTables-empty::before {
        display: none;
      }

      @include mq.mq($from: $bp) {
        border-bottom: $table-border;
        display: table-cell;
        padding: $row-padding;

        &:first-child {
          border-top: none;

          &.action-icons {
            padding-left: $row-padding * 2;
            text-align: center;
          }
        }
      }
    }

    td,
    th {
      border-bottom: none;
      padding: 0 0.5rem 0.5rem 0;

      &::before {
        line-height: 1.25rem;
        margin-bottom: 0;
      }

      &.cell-no-data {
        display: flex;
        justify-content: center;
        margin: 0;
        padding: 0;
        &::before {
          display: none;
        }
        @include mq.mq($from: $bp) {
          display: table-cell;
          padding: 0.875rem;
          text-align: center;
        }
      }
      &.cell-control,
      &.cell-actions {
        @include mq.mq($from: $bp) {
          white-space: nowrap;
          width: 1rem; //this is used to force the cell to match the width of the content

          &.detail-action-column {
            &-d {
              padding-right: 1.5rem;
            }
            &-a {
              padding-left: 0.5rem;
            }
          }
        }
      }

      &.cell-select {
        .bl-react-select {
          &-wrapper {
            margin-bottom: 0;

            @include mq.mq($from: $bp){
              width: 100%;
            }
          }
          &__control,
          &__control--is-focused {
            background-color: transparent;
            border: 0;
            box-shadow: none;
            cursor: pointer;
            min-height: 1rem;
          }
          &__indicator,
          &__indicators {
            overflow: hidden;
            padding: 0;
          }
          &__value-container {
            padding: 0;
          }
          &__single-value {
            color: variables.color("blue", 700);
          }
        }
      }

      &.cell-image {
        width: $cell-image-width;
      }

      @include mq.mq($from: $bp) {
        border-bottom: $table-border;
        padding: $row-padding;
      }

      .cell-caption,
      .caption-text,
      .caption-title {
        display: block;
        margin-bottom: 0;
      }
      .caption-text {
        @extend %font-sans-normal-400;
        color: variables.color("gray", 600);
        font-size: 0.875rem;
      }
      .caption-title {
        @extend %font-sans-normal-500;
      }
    }
  }
}

@mixin portalTableActionIcon($bp) {
  .portal-table-action-icon {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }

    &.disabled {
      color: variables.color("gray", 300);
      .portal-table-action-icon-button {
        color: variables.color("gray", 300);
      }
    }

    .portal-table-action-icon-button,
    .portal-table-action-icon-link {
      @include mq.mq($from: $bp) {
        span {
          display: none;
        }
      }
    }
  }
}


@mixin portalTableImg($bp) {
  .portal-table-img {
    $height: 3rem;

    height: $height;
    max-width: $height * math.div(16, 9);

    &.table-img-fixed-width {
      height: unset;
      max-height: $height;
      width: $height * math.div(4, 3);
      img {
        max-height: $height;
      }
    }

    &-lg {
      $height-lg: 6rem;

      height: $height-lg;
      max-width: $height-lg * math.div(16, 9);
    }

    img,
    &-lg img {
      max-height: 100%;
      width: 100%;
      @include mq.mq($from: $bp) {
        width: auto;
      }
    }
  }
}

@mixin portalTableBreakpointStyles($bp) {
  // ordering is important
  @include tableLoaderCol($bp);
  @include portalTableContainerTable($bp);
  @include tableActionRight($bp);
  @include tableCell($bp);
  @include portalTableRow($bp);
  @include portalTableActionIcon($bp);
  @include tableActions($bp);
  @include portalTableImg($bp);
}

.portal-table-filter,
.portal-table-header {
  margin: 1rem 0 1rem 0;

  @include mq.mq($from: md) {
    .filters-button,
    .action-btns .btn {
      margin-top: 0;
    }
    .action-btns .btn:not(:last-child) {
      margin-right: 1rem;
    }
  }

  @include mq.mq($from: md, $until: lg) {
    .action-btns .btn {
      flex-grow: 1;
    }
  }

  .action-btns {
    justify-content: space-between;
    margin-bottom: 0.5rem;
    @include mq.mq($from: lg) {
      justify-content: flex-end;
      margin-bottom: 0;
    }
  }

  .toolbar {
    @extend .flex-lg-row;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    &.header {
      @extend .flex-md-row;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      &:not(:first-child) {
        border-top: 0.5rem solid variables.color("teal", 700);
        padding-top: 1rem;
      }
      @include mq.mq($from: sm) {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    .subheader-standard {
      flex-basis: auto;
      margin-top: 0;
      &:not(.subheader-divider) {
        border-bottom: none;
      }
    }
    .actions {
      align-items: center;
      display: flex;
      flex-basis: auto;
      flex-wrap: wrap;
      justify-content: flex-start;
      @include mq.mq($from: sm) {
        flex-wrap: nowrap;
      }
      @include mq.mq($from: md) {
        justify-content: flex-end;
      }
      > * {
        margin-right: 1rem;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
        @include mq.mq($from: sm) {
          margin-top: 0;
        }
      }
    }
  }

  .group-header {
    color: variables.color("teal", 700);
    margin-bottom: 0.5rem;
    svg {
      margin-right: 0.125rem;
    }
  }

  .filter-type-list {
    &.expanded {
      overflow: inherit;
    }
  }

  .filter-type-list.checkbox-group.transitioned {
    @include overflow.overflow(visible);
  }

  .filter-radio {
    .custom-indicator {
      margin-bottom: 0.75rem;
    }
  }
  .checkbox-group {
    border-top: $table-border;
    margin-top: 1rem;
    max-height: none;
    &.transitioning {
      border-bottom: $table-border;
      overflow: hidden;
    }
    &.transitioned {
      border-bottom: $table-border;
    }
    .close-filters-button {
      display: block;
      margin-bottom: 1rem;
      @include mq.mq($from: md) {
        display: none;
      }
    }
    .header {
      margin-bottom: 1rem;
    }

    label {
      span {
        @extend %font-sans-normal-400;
      }
    }
  }
}

.portal-table-container {
  &.headerless {
    .portal-table-row {
      display: table-row;
      .cell-control {
        height: 100%;
        position: relative;
        ~ td {
          padding: 0.5rem;
        }
      }
    }
    table {
      thead {
        display: none;
      }
      td {
        border-bottom: $table-border;
        display: table-cell;
        padding: $row-padding;
        &::before {
          display: none;
        }
      }
    }
  }
  &.table-error {
    table {
      border-color: variables.color(danger, color) !important;
    }
  }
  .table-loader-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 2rem;
  }
}

%rte-table-styles {

  border: none !important;
  @include markdown-table.markdown-table-styles(xs);

  thead,
  tbody,
  th,
  td,
  tr {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
  }
}

.dataTable-top,
.dataTable-info {
  display: none;
}

.cell-actions {
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}

.portal-table-action-icons {
  white-space: nowrap;

  > :first-child {
    .icon {
      margin-left: 0;
    }
  }

  > :last-child {
    .icon {
      margin-right: 0;
    }
  }

  a {
    @extend .no-decoration;
  }

  .icon {
    margin: 0 0.5rem;
  }
}

.dx-g-bs4-cursor-pointer {
  cursor: pointer;
}

.dx-g-bs4-toggle-button {
  box-sizing: content-box;
  cursor: pointer;
  display: inline-block;
  margin-bottom: -0.5rem;
  margin-top: -0.5rem;
  top: 0;
}

.portal-table-divider {
  border-bottom: $table-border;
  margin-bottom: 1rem;
}

.portal-table-header {
  margin: 0 0 1rem;
}

.detail-table-title {
  color: variables.color("teal", 700);
  margin-bottom: 0.5rem;
  padding-top: 0.25rem;
}

.portal-table-sites table {
  @extend %sites-table-styles;
  @extend %override-dx-min-width-inline-style;
  @include colgroupStyles(md);
}

.table-md-breakpoint {
  @include portalTableBreakpointStyles(md);
}

.table-lg-breakpoint {
  @include portalTableBreakpointStyles(lg);
}
