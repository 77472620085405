@use "../components/icons";
@use "../functions/text";
@use "../typography/a";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../utilities/grid";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";

$card-border-color: variables.color("gray", 300);
$card-border-width: 0.125rem;
$card-padding: 0.875rem;

.card-grid {
  @include grid.grid-spacing((xs: 1.5rem, md: 2rem), true, true);

  @include grid.style-grid-columns {
    display: flex;
  }
}

.related-issuer-grid {
  @include grid.grid-spacing((xs: 1rem, md: 1.5rem, xl: 2rem), true, true);
}


.card {
  @extend %border-radius-1;
  @extend %box-shadow-1;

  background-color: variables.color("white");
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: $card-padding;
  position: relative;

  &.card-image {
    @extend %border-radius-1;
    padding: 0;

    img {
      @extend %border-radius-0125;
      margin: 0;
    }
  }

  &.card-selected {
    border-color: variables.color(link, color);
  }

  .card-body {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 1px;
    position: relative;

    > :first-child {
      margin-top: 0;
      min-height: 1px;
    }

    > :last-child {
      margin-bottom: 0;
    }

    > p,
    > .pagelink {
      margin-top: 0;
    }

    [class^="truncate-"],
    [class*=" truncate-"] {
      margin-bottom: 1rem;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .card-footer {
    margin: -$card-padding;
    margin-bottom: -$card-padding !important;
    padding: $card-padding;
  }
}

$default-card-border: $card-border-width solid $card-border-color;

.card-border {
  border: $default-card-border;
}

.card-hover {
  border: $default-card-border;
  transition: transform variables.$transition-base-duration ease;
  z-index: 3;
  &:hover {
    @extend %box-shadow-3;
    transform: scale(variables.$scale);
    &[data-href],
    &[data-toggle],
    &.card-link {
      border-color: variables.color(link, color);
      cursor: pointer;
    }
  }
  &.card-hover-disabled:hover {
    @extend %box-shadow-1;
    border: $default-card-border;
    cursor: default;
    transform: initial;
  }
}

%large-card-img {
  display: block;
  margin-bottom: 0.75rem;
  min-height: 1px; // preserve aspect ratio for ie11
  width: 100%;
}

.card-img-small {
  align-items: flex-start;
  flex-direction: row;
  min-height: 9rem;

  .card-body {
    flex: 1;
  }

  h5 {
    margin-top: 0;
  }

  .background-img {
    background-position: center center;
    background-size: cover;
    height: 6rem;
    width: 6rem;

    @include mq.mq($from: md) {
      height: 7rem;
      width: 7rem;
    }
  }

  img {
    margin-bottom: 0;
    margin-left: 1rem;
    width: 6rem;

    @include mq.mq($from: md) {
      width: 7rem;
    }
  }
}

.card-media {
  .card-body {
    flex: 0 0 auto;
  }

  img {
    @extend %large-card-img;
  }

  .icon-play .icon {
    @extend .icon-watermark;
    @extend .icon-watermark-hover;
  }
}

.card-content-item,
.card-bond-sale,
.card-media {
  .pagelink {
    margin-top: 0;
  }
}

.card-content-item,
.card-bond-sale {
  .data-horizontal {
    margin: 0;
    row-gap: 0.5rem;
  }
  .deal-issuer-logo {
    margin-bottom: 0;
    margin-left: 0.5rem;
    max-height: 2.5rem;
    max-width: 3.5rem;
    object-fit: contain;

    @include mq.mq($from: md) {
      max-height: 3.5rem;
      max-width: 4.5rem;
    }
  }
  .series-name {
    @extend %font-sans-normal-500;

    margin-bottom: 0.5rem;
  }
  .series-wrapper {
    margin-bottom: 1rem;
  }
  .actions-links {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
}

@mixin bg-overlay($color) {
  background-image: linear-gradient(0deg, $color, $color);
}

@mixin bg($color, $hover, $active) {
  @include bg-overlay(rgba($color, 0.7));

  &:hover {
    @include bg-overlay(rgba($hover, 0.7));
  }

  &:active {
    @include bg-overlay(rgba($active, 0.7));
  }
}

.card-related-issuer {
  background-color: variables.color(link, color);
  background-size: cover;
  border: none;
  display: flex;
  height: 8.5rem;
  justify-content: center;
  padding: 0;
  @include bg(variables.color(link, color-dec), variables.color(link, hover, color-dec), variables.color(link, active, color-dec));

  &.current {
    background-color: variables.color(link, active, color);
    @include bg(variables.color(link, active, color), variables.color(link, active, color), variables.color(link, active, color));
  }

  &.disabled {
    background-color: variables.color("gray", 600);
    background-image: none;
    cursor: default;
  }

  @include mq.mq($from: md) {
    height: 9.75rem;
  }

  @include mq.mq($from: xl) {
    height: 8rem;
  }

  .related-issuer {
    @extend %border-radius-1;
    @extend %font-sans-normal-500;
    @extend .no-decoration;

    padding: 0.5rem;
    text-align: center;
    text-shadow: 0 0 1rem rgba(variables.color("black"), 0.5);
    @include text.text-sizing(1rem, 1.25rem);

    &.long {
      @include text.text-sizing(0.875rem, 1rem);
    }

    @include mq.mq($from: md) {
      padding: 0.5rem 1rem;
      @include text.text-sizing(1.25rem, 1.75rem);

      &.long {
        @include text.text-sizing(1rem, 1.25rem);
      }
    }

    svg {
      width: 1em;
    }
  }
}


.card-category-grid {
  .card {
    @include mq.mq($from: sm) {
      min-height: 6.5rem;
    }
    @include mq.mq($from: md) {
      min-height: 7.25rem;
    }
    .card-body {
      justify-content: space-between;
    }
  }
}

.aspect-ratio-container {
  height: 0;
  overflow: hidden;
  position: relative;

  &[data-ratio="4:5"] {
    padding-top: 125%;
    .flex-centered img {
      max-height: 100%;
      max-width: none;
    }
  }

  &[data-ratio="16:9"] {
    padding-top: 56.25%;
  }

  .aspect-ratio-content {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .flex-centered {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

.card-title {
  @extend %font-sans-normal-700;
  @include text.text-sizing(1.125rem, 1.5rem);
}

.card-roadshow {
  .logo-slide {
    height: 80%;
    object-fit: contain;
  }
}

.card-new-issuer {
  padding: 1rem;

  .badge {
    i {
      margin-right: 0.25rem;
    }
  }

  .image {
    align-items: flex-start;
    display: flex;
    justify-content: end;
    max-height: 3rem;
    max-width: 4rem;

    @include mq.mq($from: md) {
      max-height: 3.75rem;
      max-width: 5rem;
    }

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.data-point-card,
.card-content-item {
  .badge {
    display: flex;
  }
}

.data-point-card {
  margin-bottom: 1rem;

  @include mq.mq($from: md) {
    margin-bottom: 2rem;
  }

  .card-body {
    .facts-with-label {
      margin-bottom: 1rem;

      .data-horizontal {
        row-gap: 0.5rem;
      }
    }
  }

  .card-footer {
    .data-horizontal {
      row-gap: 1rem;
    }

    .data-point {
      justify-content: start;
      dt {
        max-width: 4.875rem;
      }

      .analytics-metric {
        font-size: 1.5rem;
      }
    }
  }
}
