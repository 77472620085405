@use "../../components/card";
@use "../../functions/text";
@use "../../typography/fonts";
@use "../../utilities/shadows";
@use "../../variables";
@use "../../mq";

$thumbs-wrapper-margin: 1.25rem;
$thumbs-min-width: 6.5rem;
$thumbs-container-width: ($thumbs-min-width + $thumbs-wrapper-margin) * 2;

.carousel {
  position: relative;

  &.carousel-slider {
    margin: 0;
    position: relative;
  }

  .slider-wrapper {
    width: 100%;
  }

  &-root {
    overflow: hidden;
  }

  .slider {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    &.animated {
      transition: variables.$transition-base;
    }
  }

  .slide {
    flex: 0 0 100%;
    margin: 0;
    padding-left: 0;
  }
}

.dashboard-data-carousel .carousel {
  $title-desc-lg-m-r: 1.5rem;
  $title-desc-lg-w: 13.5rem;
  width: $thumbs-container-width;

  @include mq.mq($from: sm) {
    width: 100%;
  }

  &-root {
    background: variables.color("white");
    box-shadow: inset 0 0.75rem 0.5rem -0.75rem rgba(0, 0, 0, 0.24),
      inset 0 -0.75rem 0.75rem -0.75rem rgba(0, 0, 0, 0.24);
    margin: 0.5rem -1rem 0;
    padding: 0.5rem 0 1.5rem;

    @include mq.mq($from: md) {
      margin: 1rem -3rem 0;
    }
  }

  .slide {
    background: variables.color("white");

    .slide-content {
      @include mq.mq($from: md) {
        padding: 1rem 3rem;
      }

      @include mq.mq($from: lg) {
        padding: 1rem 5rem;
      }

      @include mq.mq($from: xl) {
        padding: 1rem 6rem;
      }
    }
  }

  .chart {
    @include mq.mq($from: lg) {
      float: right;
      width: calc(100% - #{$title-desc-lg-w + $title-desc-lg-m-r});
    }

    .error {
      background-color: variables.color("gray", 200);
    }
  }

  .title-description {
    align-items: flex-start;

    @include mq.mq($from: lg) {
      float: left;
      margin-right: $title-desc-lg-m-r;
      max-width: $title-desc-lg-w;
    }

    h3 {
      margin-top: 0.5rem;
      max-width: 13.5rem;
      @include mq.mq($from: sm) {
        max-width: none;
      }
    }

    h6 {
      margin-bottom: 0.5rem;
    }

    .logo {
      &.mma {
        svg {
          max-width: 12.5rem;
        }
      }

      &.ice-data-services {
        svg {
          max-height: 2.25rem;
          max-width: 6rem;
        }
      }

      &.bondlink-icon {
        svg {
          max-width: 6rem;
        }
      }
      svg {
        margin-bottom: 1rem;
        max-height: 1rem;
      }
    }
  }

  .control-arrow {
    background: none;
    border: 0;
    box-shadow: none;
    color: variables.color("blue", 700);
    cursor: pointer;
    margin: 0 1rem;
    outline: 0;
    position: absolute;
    top: 0.5rem;
    z-index: deep-map-get(variables.$z-indexes, base);

    &:hover {
      color: variables.color(link, hover, color);
    }

    &.control-next {
      right: 0;
    }

    &.control-prev {
      right: 3.25rem;
    }

    &.control-disabled {
      color: variables.color(link, disabled, color);
      cursor: inherit;
      transform: none;
    }

    @include mq.mq($from: md) {
      top: 1rem;
    }

    @include mq.mq($from: lg) {
      top: 50%;

      &.control-next {
        right: 0;
      }

      &.control-prev {
        left: 0;
        right: auto;
      }
    }

    svg.icon {
      height: 1.75rem;
      width: 1.75rem;

      @include mq.mq($from: lg) {
        height: 3rem;
        width: 3rem;
      }
    }
  }

  .thumbs-wrapper {
    margin: $thumbs-wrapper-margin;
  }

  .thumbs {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: variables.$transition-base;
    white-space: nowrap;

    @include mq.mq($from: md) {
      justify-content: center;
    }
  }

  .thumb {
    background: variables.color("white");
    border: card.$card-border-width solid card.$card-border-color;
    cursor: pointer;
    display: inline-block;
    flex-direction: column;
    margin: 0;
    margin-left: -(card.$card-border-width);
    min-width: $thumbs-min-width;
    overflow: hidden;
    padding: 0.5rem;
    white-space: normal;

    &:focus {
      @extend %focus-shadow;
    }

    &:first-child {
      border-radius: variables.$border-radius-1 0 0 variables.$border-radius-1;
      margin-left: 0;
    }

    &:last-child {
      border-radius: 0 variables.$border-radius-1 variables.$border-radius-1 0;
    }

    &.selected {
      border-color: variables.color("black");
      z-index: deep-map-get(variables.$z-indexes, base);

      span {
        color: variables.color("black");
      }
    }

    @include mq.mq($from: lg) {
      border-radius: variables.$border-radius-1;
      margin-left: 0;
      margin-right: 0.75rem;
      min-width: 9.5rem;

      &:first-child {
        border-radius: variables.$border-radius-1;

      }

      &:last-child {
        border-radius: variables.$border-radius-1;
      }
    }

    &-items {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;

      @include mq.mq($from: lg) {
        flex-direction: row;
        text-align: left;
      }
    }

    span {
      @extend %font-sans-normal-700;
      color: variables.color("blue", 700);
      @include text.text-sizing(1rem, 1.25rem);
    }

    svg.icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .mci-metric {
    padding: 0.5rem;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }
}
