@use "../variables";
@use "../mq";
@use "../typography/h";
@use "../utilities/borders";
@use "../utilities/shadows";

@mixin markdown-table-styles($bp) {
  $min-cell-width: 5rem;
  margin-bottom: 1.5rem;

  thead {
    display: none;

    @include mq.mq($from: $bp) {
      display: table-header-group;
    }
  }

  tbody tr:hover {
    background-color: variables.color(link, bg);
  }

  tr,
  th,
  td {
    display: block;
    padding: 0;
  }

  tr {
    @extend .bb-0125;

    padding: 0.5rem 0.5rem 0;

    &.highlight-primary {
      @extend %box-shadow-2;
      border: 0.125rem solid var(--accent-1-700);
    }

    @include mq.mq($from: $bp) {
      display: table-row;
      padding-top: 0;
    }
  }

  th {
    @extend %h4;

    color: var(--accent-1-700);
    display: none;
    min-width: $min-cell-width;
    padding: 1rem 0.5rem 0.5rem;
    text-align: left;
    vertical-align: bottom;
    word-break: keep-all;

    @include mq.mq($from: $bp) {
      display: table-cell;
    }
  }

  td {
    color: variables.color("gray", 800);
    margin-bottom: 0.5rem;
    min-width: $min-cell-width;

    @include mq.mq($from: $bp) {
      display: table-cell;
      padding: 0.75rem 0.5rem;
    }

  }
}
