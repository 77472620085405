@use "sass:map";
@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/components/form";
@use "../../../../../assets/stylesheets/functions/breakpoints";
@use "../../../../../assets/stylesheets/typography/fonts";
@use "../../../../../assets/stylesheets/typography/h";
@use "../../../../../assets/stylesheets/utilities/grid";
@use "../../../../../assets/stylesheets/utilities/overlays";
@use "../../../../../assets/stylesheets/utilities/shadows";
@use "../../../../../assets/stylesheets/variables";

@mixin masthead-height($nav-heights, $masthead-heights, $nav-logo-heights) {
  @each $bp, $nav-height in $nav-heights {
    @include breakpoints.bp-up($bp) {
      height: deep-map-get($masthead-heights, $bp) + $nav-height + if($nav-logo-heights, map.get($nav-logo-heights, $bp), 0);
      padding-top: $nav-height + if($nav-logo-heights, map.get($nav-logo-heights, $bp), 0);
    }
  }
}

.masthead {
  @extend .overlay-radial-gray-to-transparent;

  background: var(--accent-1-700);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  &.issuer-masthead {
    @include masthead-height(variables.$issuer-nav-bar-heights, variables.$issuer-masthead-heights, variables.$issuer-nav-logo-heights);

    .home & {
      @include masthead-height(variables.$issuer-nav-bar-heights, variables.$issuer-masthead-home-heights, variables.$issuer-nav-logo-heights);
    }
  }

  &.bank-masthead {
    @include masthead-height(variables.$bank-nav-bar-heights, variables.$bank-masthead-heights, null);

    &.has-alert {
      margin-top: 4.25rem;
    }

    h2 {
      @extend %text-shadow-2;

      @include mq.mq($from: md) {
        @extend %text-shadow-md-4;
      }
    }
  }

  &.deal-masthead {
    @include masthead-height(variables.$deal-nav-bar-heights, variables.$deal-masthead-heights, null);

    h1 {
      @extend h2;
      @extend .inverted;

      margin-bottom: 0;

      &.long-name {
        @extend h3;
        @extend .inverted;
      }
    }
  }

  .page-name-container {
    align-items: flex-end;
    bottom: 0.75rem;
    display: flex;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: deep-map-get(variables.$z-indexes, base);

    @include mq.mq($from: md) {
      bottom: 1.5rem;
    }

    .breadcrumb {
      @extend %font-sans-normal-700;
      @extend %text-shadow-1;

      border-bottom-width: 0.125rem;
      font-size: 1rem;
      line-height: 1.25rem;

      @include mq.mq($from: md) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .page-name {
      @extend .container;
      flex: 0 0 100%;
    }
  }
}
