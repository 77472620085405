@use "../variables";
@use "../typography/h";

.badge {
  $b-padding: 0.1875rem;
  $badge-line-height: 1rem;
  @extend %h4;
  align-items: center;
  border-radius: 0.25rem;
  color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  display: inline-flex;
  justify-content: center;
  line-height: $badge-line-height;
  margin: 0;
  min-width: deep-map-get(variables.$h-props, h4, xs, font-size) + ($b-padding * 2);
  text-align: center;
  text-transform: uppercase;
  word-break: keep-all;
  &.tall {
    padding: 0.25rem 0.5rem;
  }
  &.short {
    padding: 0.125rem 0.25rem;
  }
  .icon {
    height: $badge-line-height;
    margin-right: 0.25rem;
    width: $badge-line-height;
  }
}
