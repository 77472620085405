@use "sass:map";
@use "../components/form";
@use "../functions/breakpoints";
@use "../functions/map" as m;
@use "../functions/shadows" as shadowFns;
@use "../functions/text";
@use "../functions/truncate";
@use "../typography/a";
@use "../mq";
@use "../utilities/shadows";
@use "../variables";

$issuer-logo-padding-xs-x: 0.75rem;
$issuer-logo-padding-xs-y: 0.75rem;
$issuer-logo-padding-md: 1rem;

$issuer-logo-full-width-props: (
  order: 3,
  width: 100vw,
);

$issuer-logo-border-radius-props: (
  border-bottom-left-radius: 0.25rem,
  border-bottom-right-radius: 0.25rem,
);

$issuer-logo-std-width-props: m.deep-map-merge($issuer-logo-border-radius-props, (
  margin: 0,
  order: 0,
  width: auto,
));

$issuer-logo-container-props: (
  xs: $issuer-logo-full-width-props,
  md: $issuer-logo-std-width-props,
);

$issuer-logo-container-sticky-props: (
  xs: (
    height: deep-map-get(variables.$issuer-nav-bar-sticky-heights, xs),
    margin-right: 1rem,
    order: 0,
    width: 4rem,
  ),
  md: (
    height: deep-map-get(variables.$issuer-nav-bar-heights, md),
    width: auto,
  ),
);

$bank-logo-container-props: (
  xs: (
    order: 3,
  ),
  md: (
    order: 0,
  ),
);

$bank-logo-container-sticky-props: (
  xs: (
    order: 3,
  ),
  md: (
    order: 0,
  ),
);

@mixin issuer-logo-padding {
  padding: $issuer-logo-padding-xs-y $issuer-logo-padding-xs-x;

  @include mq.mq($from: md) {
    padding: $issuer-logo-padding-md;
  }
}

@mixin issuer-logo-image-sizing {
  height: map.get(variables.$issuer-nav-logo-heights, xs) - ($issuer-logo-padding-xs-y * 2);
  margin-right: 1rem;
  width: map.get(variables.$issuer-nav-logo-heights, xs) - ($issuer-logo-padding-xs-x * 2);
}

@mixin bank-logo-image-sizing {
  height: 4rem;

  @include mq.mq($from: md) {
    height: variables.$bank-nav-bar-md-height;

    .sticky & {
      height: variables.$bank-nav-bar-sticky-md-height;
    }
  }

  .sticky & {
    height: variables.$bank-nav-bar-sticky-xs-height;
  }
}

@mixin primary-header-square-default-xs-font {
  &.short {
    @include text.text-sizing(1.375rem, 1.75rem);
  }
  &.medium {
    @include text.text-sizing(1.125rem, 1.5rem);
  }
  &.long {
    @include text.text-sizing(1rem, 1.25rem);
  }
}

@mixin primary-header-square-default-md-font {
  &.short {
    @include text.text-sizing(1.75rem, 2rem);
  }
  &.medium {
    @include text.text-sizing(1.375rem, 1.75rem);
  }
  &.long {
    @include text.text-sizing(1.125rem, 1.5rem);
  }
}

@mixin primary-header-horizontal-default-xs-font {
  &.short {
    margin-top: 0.5rem;
    @include text.text-sizing(1.375rem, 1.75rem);
  }
  &.medium {
    margin-top: 0.5rem;
    @include text.text-sizing(1rem, 1.25rem);
  }
  &.long {
    margin-top: 0.5rem;
    @include text.text-sizing(0.875rem, 1.125rem);
  }
}

@mixin primary-header-horizontal-default-md-font {
  &.short {
    @include text.text-sizing(1.375rem, 1.75rem);
  }
  &.medium {
    @include text.text-sizing(1.125rem, 1.5rem);
  }
  &.long {
    @include text.text-sizing(1rem, 1.25rem);
  }
}

.issuer-logo-container {
  @extend %box-shadow-1;

  background-color: variables.color("white");
  position: relative;
  z-index: deep-map-get(variables.$z-indexes, header) + 1;

  @include breakpoints.bp-props($issuer-logo-container-props);
  @include mq.mq($until: md) {
    margin-left: -(variables.$bondlink-bar-padding-lr-xs);
    margin-right: -(variables.$bondlink-bar-padding-lr-xs);
  }

  .open & {
    box-shadow: none;
  }

  .sticky & {
    @include breakpoints.bp-props($issuer-logo-container-sticky-props);

    @include mq.mq($from: md) {
      @include shadowFns.box-shadow(2);
    }
  }

  .related-issuers-filter {
    cursor: pointer;
    width: 100%;

    .sticky & {
      display: none;
    }

    .custom-select-wrapper {
      &.show {
        .custom-select-options {
          visibility: visible;
        }
      }
    }

    // Accommodates spacing for full-width select dropdown.
    .custom-select {
      $custom-select-padding-y: 0.75rem;

      background-position: right $issuer-logo-padding-xs-x center;
      border: 0.125rem solid transparent;
      border-bottom-color: variables.color("gray", 200);
      border-top-color: variables.color("gray", 200);
      box-shadow: none;
      padding: $custom-select-padding-y $issuer-logo-padding-xs-x;

      &.focus { // stylelint-disable-line order/order
        border-color: var(--accent-1-700);
      }

      @include mq.mq($from: md) {
        background-position: right $issuer-logo-padding-md center;
        padding: $custom-select-padding-y $issuer-logo-padding-md;
      }
    }

    .custom-select-options {
      display: block;
      visibility: hidden;
    }

    .custom-option {
      padding: 0;
    }

    // Add the padding to increase click area and tooltip trigger area
    .custom-option > .related-issuer,
    .custom-option > .tooltip > .related-issuer {
      color: variables.color("gray", 800);
      display: block;
      padding: form.$custom-select-option-padding-y $issuer-logo-padding-xs-x;
      width: 100%;

      @include a.no-decoration();

      &:hover,
      &:active,
      &:focus {
        color: var(--accent-1-500);
      }

      @include mq.mq($from: md) {
        padding-left: $issuer-logo-padding-md;
        padding-right: $issuer-logo-padding-md;
      }
    }
    .custom-option.selected > .related-issuer {
      @extend .inverted;
    }
  }
}

.issuer-logo {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  @include issuer-logo-padding;

  &.square {
    flex-direction: row;

    .image {
      flex-shrink: 0;
      margin-right: 0.75rem;
      max-height: 5rem;
      max-width: 5rem;
      overflow: hidden;

      @include mq.mq($from: md) {
        margin-right: 1rem;
        max-height: 6rem;
        max-width: 6rem;
      }
    }

    .issuer-headers {
      align-self: center;
      flex-basis: 12rem;

      @include mq.mq($from: sm) {
        flex-basis: 16rem;
      }

      .headers-primary {
        @include primary-header-square-default-xs-font;

        @include mq.mq($from: md) {
          @include primary-header-square-default-md-font;
        }
      }

      .headers-secondary {
        font-size: 0.875rem;
        line-height: 1rem;
        margin-top: 0.25rem;

        @include mq.mq($from: md) {
          font-size: 1.125rem;
          line-height: 1.5rem;
        }
      }
    }
  }

  &.horizontal {
    $logo-horiz-padding-y: 0.75rem;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $logo-horiz-padding-y;
    padding-top: $logo-horiz-padding-y;

    .image {
      height: 3rem;
      margin: 0 auto;
      overflow: hidden;
      width: 18rem;

      @include mq.mq($from: md) {
        height: 4rem;
        width: 22rem;
      }
    }

    .issuer-headers {
      .headers-primary {
        text-align: center;

        @include primary-header-horizontal-default-xs-font;

        @include mq.mq($from: md) {
          @include primary-header-horizontal-default-md-font;
        }
      }

      .headers-secondary {
        display: none;
      }
    }
  }

  @include mq.mq($from: md) {
    position: relative;
  }

  .main-nav.sticky & {
    justify-content: center;
    padding: 0.25rem;
    width: 4rem;

    @include mq.mq($from: md) {
      height: 100%;
      padding: 0.5rem;
      width: 6.5rem;
    }

    .issuer-headers {
      display: none;
    }
  }

  .image {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;

    &:not(.horizontal) {
      @include issuer-logo-image-sizing;
    }

    img {
      margin: 0;
      max-height: 100%;
      max-width: 100%;
      min-width: 1rem; // Fix IE11 bug that ignores max-width inside flex container.
    }

    .main-nav.sticky & {
      margin: 0 !important;
      max-height: 2.75rem;
      max-width: 3.5rem;

      @include mq.mq($from: md) {
        height: 4rem;
        max-height: 4rem;
        max-width: 6.5rem;
        width: 100%;
      }

      img {
        display: block;
      }
    }
  }

  .issuer-headers {
    width: 100%; // Req for IE11 to prevent text overflow.
  }

  .headers-secondary {
    color: var(--accent-2-700);
    margin-bottom: 0;
  }

  .main-nav:not(.sticky) & {
    @include breakpoints.bp-prop(width, variables.$issuer-logo-widths);
  }
}

.about-issuer-logo {
  max-height: 7.5rem;
  max-width: 12.5rem;
}

$bank-actions-links-md-width: 20.5rem;
$bank-actions-links-lg-width: 21.5rem;
$bank-logo-container-md-margin-right: 2rem;

.bank-logo-container {
  flex-grow: 1;

  @include breakpoints.bp-props($bank-logo-container-props);

  @include mq.mq($from: md) {
    max-width: calc(100vw - $bank-actions-links-md-width - (deep-map-get(variables.$bank-nav-bar-padding-x, md) * 2) - $bank-logo-container-md-margin-right);
  }

  @include mq.mq($from: lg) {
    max-width: calc(100vw - $bank-actions-links-lg-width - (deep-map-get(variables.$bank-nav-bar-padding-x, lg) * 2) - $bank-logo-container-md-margin-right);
  }
}

.bank-logo {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  .image {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 0.75rem;
    @include bank-logo-image-sizing;

    img {
      margin: 0;
      max-height: 100%;
      max-width: 100%;
      min-width: 1rem; // Fix IE11 bug that ignores max-width inside flex container.
    }
  }

  .bank-headers {
    align-self: center;

    .headers-primary {
      @extend .truncate;
      @include text.text-sizing(1.25rem, 2rem);

      @include mq.mq($until: md) {
        .sticky & {
          @include truncate.truncate-lines(1);
        }
      }

      @include mq.mq($from: md) {
        @include text.text-sizing(1.5rem, 1.75rem);
        .sticky & {
          @include truncate.truncate-lines(2);
        }
      }
    }
  }
}
