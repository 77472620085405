@use "../../../../../assets/stylesheets/components/buttons";
@use "../../../../../assets/stylesheets/functions/overflow";
@use "../../../../../assets/stylesheets/mq";
@use "../../../../../assets/stylesheets/typography/a";
@use "../../../../../assets/stylesheets/typography/fonts";
@use "../../../../../assets/stylesheets/utilities/shadows";
@use "../../../../../assets/stylesheets/variables";

body.roadshow-player {
  width: 100%;

  .page-container {
    max-width: none;
  }
}

.roadshow {
  display: flex;
  flex-direction: column;
  height: 100vh;

  @include mq.mq($from: lg) {
    height: 100vh;
  }
}

.roadshow-container {
  $roadshow-btn-p: 0.5rem;

  background: variables.color("black");
  display: flex;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;

  .offering-statement,
  .contact-btn,
  #slide-list-toggle {
    @extend %font-sans-normal-400;

    text-transform: capitalize;
  }

  .offering-documents-title {
    color: variables.color("gray", 400);
  }

  .offering-statement-link {
    @extend %anchor-state-styles-white;
  }

  .slide-list-container {
    @extend %box-shadow-2;

    background: variables.color("gray", 900);
    flex: 0 0 20rem;
    height: calc(100vh - #{deep-map-get(variables.$issuer-nav-bar-heights, xs)});
    -ms-overflow-style: -ms-autohiding-scrollbar;
    position: absolute;
    transform: translateX(-100%);
    transition: transform variables.$transition-base-duration ease;
    width: 20rem;
    @include overflow.overflow(scroll);

    &.open,
    &.open-initial-pinned {
      transform: translateX(0);
      z-index: deep-map-get(variables.$z-indexes, header);
    }

    @include mq.mq($from: lg) {
      flex-basis: 24rem;
      height: calc(100vh - #{variables.$roadshow-header-md-height});
      width: 24rem;
      @include overflow.overflow(hidden);

      &.open,
      &.open-initial-pinned {
        position: relative;
      }
    }

    dt {
      color: variables.color("gray", 400);
    }
  }

  .offering-details {
    @extend %box-shadow-1;
    padding: 1rem;

    .data-point {
      &:first-child {
        border-color: variables.color("gray", 700);
      }

      dt {
        color: variables.color("gray", 400);
      }
    }
  }

  h4.issuer-name {
    color: variables.color("gray", 200);
  }

  .offering-name {
    margin-bottom: 0;
  }

  .btn-primary.roadshow-btn-white {
    @extend %text-shadow-2;

    background: transparent;
    color: variables.color("gray", 200);
    padding: $roadshow-btn-p;
    text-transform: capitalize;

    &:hover,
    &:active {
      background: transparent;
      color: variables.color("white"); // stylelint-disable-line
    }

    &:focus {
      @extend %focus-shadow;
    }

    &[disabled] {
      background: transparent;
      color: variables.color(disabled, color);
    }
  }

  .slide-list {
    height: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include overflow.overflow(auto);

    @include mq.mq($from: md) {
      height: 100%;
    }
  }

  .slide-text {
    display: flex;
    flex: 1 1 0;
    flex-flow: column nowrap;
    padding: 0 1rem;
  }

  .slide-name {
    font-size: 1rem;
  }

  .slide-secondary-text {
    @extend .small;
    color: variables.color("gray", 300);
  }

  .time {
    color: variables.color("gray", 300);
    flex-shrink: 0;

    .active & {
      color: variables.color("white"); // stylelint-disable-line
    }
  }

  .slides {
    margin-top: 0;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @include overflow.overflow(auto, y);
  }

  .slide {
    align-items: center;
    background: variables.color("gray", 700);
    cursor: pointer;
    display: flex;
    height: 5.5rem;
    margin-bottom: 0.125rem;
    padding: 1rem;

    &:hover,
    &:focus,
    &:active {
      background: var(--accent-1-700);

      .time,
      .slide-secondary-text {
        color: variables.color("white"); // stylelint-disable-line
      }
    }

    &.viewed {
      background: variables.color("gray", 700);
    }

    &.active {
      background: variables.color("black");
    }

    img {
      @extend %box-shadow-2;
    }
  }

  .slide-thumbnail {
    align-items: center;
    display: flex;
    flex: 0 0 5.5rem;
    height: 100%;
    width: 5.5rem;

    .slide-image {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      img {
        margin: 0;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  .slide-container {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;

    @include mq.mq($from: md) {
      height: 100%;
    }
  }

  .main-slide-container {
    align-items: center;
    height: 100%;
    margin-bottom: 1rem;
    min-height: 0;
    width: 100%;
  }

  .main-slide {
    align-items: center;
    display: flex;
    height: 100%;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: 100%;

    .main-slide-image {
      display: block;
      height: auto;
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      width: auto;
    }
  }

  .playback-controls {
    flex: 0 0 auto;
    margin-bottom: auto;
    padding-top: 0.5rem;

    @include mq.mq($from: md) {
      margin-bottom: 0;
    }
  }

  .player-next,
  .player-previous {
    &[disabled] {
      color: variables.color("gray", 700);
      cursor: not-allowed;
    }
  }

  .player-previous {
    margin-left: -$roadshow-btn-p;
  }

  .player-volume {
    margin-right: -$roadshow-btn-p;
  }

  .progress-container {
    display: flex;

    button {
      @extend .btn-no-transform;
      margin-top: 0;
    }
  }

  .progress {
    width: 100%;
  }

  .progress-bar-container {
    background: variables.color("gray", 700);
    border-radius: 0.125rem;
    height: 0.5rem;
    width: 100%;
  }

  .progress-bar {
    background: variables.color("white");
    border-radius: 0.125rem;
    height: 100%;
  }

  .progress-buttons {
    display: flex;
    justify-content: space-between;

    svg {
      margin: 0;
    }
  }

  .player-button {
    border-radius: 2rem;
    height: 2.5rem;
    margin-right: 1rem;
    margin-top: 0;
    padding-left: 0.4375rem;
    padding-right: 0.4375rem;
    width: 2.5rem;
  }

  #play-pause {
    align-items: center;
    display: flex;
    justify-content: center;

    svg {
      margin: 0;
    }
  }

  .viewed-mark-container {
    transform: scale(0.75);
  }

  .speed-container {
    .tippy-arrow::before {
      border-top-color: variables.color("gray", 900) !important;
    }

    .bl-tooltip-content-container {
      background: linear-gradient(to bottom, variables.color("gray", 700), variables.color("gray", 900));
      border-radius: 0.25rem;
      color: variables.color("gray", 200);
      min-width: unset;
      padding: 0.5rem 0;
      text-align: center;
      width: 4.5rem;

      .speed-list > div {
        color: variables.color("gray", 200);

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: variables.color("gray", 200);
          color: variables.color("gray", 900);
        }
      }
    }
  }

  .volume-container {
    position: relative;

    .tippy-arrow {
      display: none;
    }

    .tippy-box {
      background-color: transparent;
    }

    input[type=range] {
      @mixin thumb() {
        background: variables.color("white");
        border: 1px solid variables.color("black");
        border-radius: 0.5rem;
        cursor: pointer;
        height: 1rem;
        width: 1rem;
      }

      @mixin track() {
        background: variables.color("gray", 700);
        border-radius: 0.5rem;
        cursor: pointer;
        height: 0.9375rem;
        width: 100%;
      }

      @mixin ms-fill() {
        background: variables.color("gray", 700);
        border-radius: 0.5rem;
      }

      -webkit-appearance: none;
      background: transparent;
      border: none;
      bottom: 2rem;
      box-shadow: none;
      position: absolute;
      right: -1rem;
      transform: rotate(-90deg);
      width: 8.875rem;

      &:focus {
        outline: none;
      }

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        @include thumb();
      }

      &::-ms-thumb {
        @include thumb();
      }

      &::-ms-track {
        background: transparent;
        border-color: transparent;
        color: transparent;
        cursor: pointer;
        height: 1rem;
        width: 100%;
      }

      &::-webkit-slider-runnable-track {
        @include track();
      }

      &::-moz-range-track {
        @include track();
      }

      &::-ms-fill-lower,
      &::-ms-fill-upper {
        @include ms-fill();
      }
    }
  }
}

.roadshow-nav-2020-container {
  background-color: variables.color(link, color);
  display: flex;
  flex-direction: column;
  padding: 0.75rem;

  .roadshow-logo-container {
    margin-bottom: 0.75rem;

    @include mq.mq($from: md) {
      display: none;
    }

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-height: 1.5rem;
      max-width: 100%;
    }
  }

  .roadshow-nav-buttons {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .hide-initial-pinned {
      visibility: hidden;

      @include mq.mq($from: lg) {
        visibility: visible;
      }
    }

    .btn {
      flex: 0 0 auto;
      margin-top: 0;
    }

    .roadshow-logo {
      max-height: 1.625rem;
    }

    .roadshow-logo-large {
      display: none;

      @include mq.mq($from: md) {
        display: block;
        margin: 0 auto 0 auto;
      }
    }
  }
}
