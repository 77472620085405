@use "../../functions/overflow";
@use "../../functions/overlay";
@use "../../functions/transitions";
@use "../../variables";
@use "../../mq";

$control-size: 2rem;
$control-font-size: calc($control-size * 0.75);

.image-carousel-modal {
  @extend %fade;
  opacity: 0;
  visibility: hidden;
  @include overlay.overlay(0, 1, lightbox , rgba(0, 0, 0, 0.9));
  @include overflow.overflow(hidden, x);

  &.show {
    flex-direction: column-reverse;
    justify-content: space-between;
    opacity: 1;
    visibility: visible;
    @include mq.mq($from: md) {
      flex-direction: column;
    }
  }

  .carousel-controls {
    flex: none;
  }
  .control {
    &-arrow svg {
      height: $control-size;
      touch-action: none;
      width: $control-size;
    }
    &-text {
      font-size: $control-font-size;
      line-height: $control-size;
    }
    &-disabled {
      display: none;
    }
  }

  .carousel-root {
    height: 100%;
    position: relative;
    @include mq.mq($from: md) {
      height: 60%;
    }

    .carousel-slider {
      display: flex;

      .slider,
      .slide,
      & {
        height: 100%;
      }

      .slider {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .slide {
        align-items: center;
        display: flex;
        flex: 0 0 100%;
        justify-content: center;
        margin: 0;
        opacity: 50%;
        padding: 4rem 1rem 0 1rem;
        width: 100%;
        &.selected {
          opacity: 100%;
        }
        @include mq.mq($from: md) {
          padding: 0 5rem;
        }

        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .thumbnails {
    display: none;
    gap: 1.5rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
    @include overflow.overflow(scroll, x);

    @include mq.mq($from: md) {
      display: flex;
    }

    .thumbnail {
      align-items: center;
      background-color: variables.color("gray", 900);
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 5rem;
      opacity: 50%;
      width: 8.5rem;
      &:first-child{
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
      &.current {
        opacity: 100%;
        &::after {
          background-color: variables.color(link, color);
          content: "";
          height: 0.5rem;
          margin-top: -0.5rem;
          width: 100%;
        }
      }
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

