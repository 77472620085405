@use "../functions/icon";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";

.subheader-standard {
  border-bottom: borders.$border-200;
  flex-basis: 100%;
  margin-top: 1rem;
  &.draft-subheader {
    border-bottom: none;
    flex-direction: column;
    margin-bottom: 1rem;
    @include mq.mq($from: md) {
      flex-direction: row;
    }
    .btn {
      margin-bottom: 0;
      margin-top: 1rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      @include mq.mq($from: md) {
        margin-top: 0;
      }
    }
  }

  &.subheader-h3 {
    margin-bottom: 1rem;
    @include mq.mq($from: md) {
      align-items: flex-end;
    }
    h3 {
      margin-bottom: 0;
    }
    .btn,
    .btn-link {
      margin: 0 !important;
    }
  }

  @include mq.mq($from: md) {
    align-items: flex-start;
  }
  .btn,
  .btn-link,
  .bl-react-select-wrapper.button-select {
    flex-shrink: 0;
    @include mq.mq($from: md) {
      margin: 0;
    }
  }
  .icon-circle-title {
    @extend %icon-circle;
    @extend %box-shadow-1;
    @extend %font-sans-normal-700;
    align-items: center;
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 0.5rem;
    padding: 0;
    &.small {
      height: 1.75rem;
      margin-right: 0.5rem;
      width: 1.75rem;
      p {
        margin: 0;
      }
    }
    &.warning {
      background-color: variables.color(warning, alt-color);
    }
    &.danger {
      background-color: variables.color(danger, "color");
    }
    &.success {
      background-color: variables.color(success, "color");
    }
    &.disabled {
      background-color: variables.color("gray", 600);
    }

    h3 {
      color: inherit;
      margin: 0;
    }
  }
  .icon-title-link {
    align-items: center;
    display: flex;
    @include mq.mq($from: md) {
      align-items: flex-start;
    }
  }
  .title-link {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    h5 {
      @extend %font-sans-normal-700;
      margin: 0;
    }
  }
  h2 {
    flex-shrink: 1;
    margin: 0.25rem 0 0 0;
    @include mq.mq($from: md) {
      margin: 0;
    }
  }
  h3 {
    margin: 0;
    @include mq.mq($from: md) {
      margin: 0.5rem 0;
    }
  }

  .card-header {
    display: flex;
    .title-container {
      display: flex;
      flex-direction: column;
      margin-left: 0.5rem;
      h3 {
        margin: 0;
      }
      h4 {
        &.warning {
          color: variables.color(warning, "color");
        }
        &.danger {
          color: variables.color(danger, "color");
        }
        &.success {
          color: variables.color(success, "color");
        }
        &.disabled {
          color: variables.color("gray", 600);
        }
      }
    }
  }
}

.subheader-draft {
  $svg-size-xs: deep-map-get(variables.$h-props, h3, xs, font-size);
  $svg-size-md: deep-map-get(variables.$h-props, h3, md, font-size);
  &.header {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    svg {
      height: $svg-size-xs;
      width: $svg-size-xs;
      @include mq.mq($from: md) {
        height: $svg-size-md;
        width: $svg-size-md;
      }
    }
  }
  &.single-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mq.mq($from: md) {
      flex-direction: row;
    }
    .label-container {
      margin: auto 0 0.125rem auto;
      p {
        margin: 0;
      }
    }
  }
  h3 {
    margin: 0;
    margin-right: 0.5rem;
  }
  svg {
    fill: variables.color(warning, "alt-color");
    height: 1.5rem;
    width: 1.5rem;
    @include mq.mq($from: sm) {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
}

.subheader-documents {
  .icon-title-link {
    margin-bottom: 0;
  }
}

.subheader-with-subtitle {
  $subtitle-margin-bottom: 0.5rem;

  .header-content-flex {
    button {
      margin-bottom: $subtitle-margin-bottom;
    }
  }

  .icon-title-link {
    margin-bottom: $subtitle-margin-bottom;
  }
}

.subheader-deal-details {
  margin-top: 0;

  .deal-details-button-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 1rem;
    margin: 1rem 0;

    @include mq.mq($from: md) {
      flex-direction: row;
    }

    @include mq.mq($from: lg) {
      margin-top: 0;
    }
  }
}
