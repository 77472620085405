@use "../utilities/borders";

.select-buttons {
  .select-all {
    border-right: borders.$border-300;
    display: inline-block;
    padding-right: 1rem;
  }

  .deselect-all {
    display: inline-block;
    padding-left: 1rem;
  }

  .deselect-all,
  .select-all {
    .btn-link {
      border: none;
    }
  }
}
